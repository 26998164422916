import { useState, React, useRef, useEffect, memo } from 'react';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useHistory } from 'react-router-dom';
import {
  CancelShipment,
  CheckLocation,
  DispatchShipment,
  GetAagencyUserDetails,
  GetAgencyLocations,
  GetCarriers,
  GetNextProNumber,
  GetQuote,
  GetShipmentActivity,
  GetShipmentDetails,
  GetShipments,
  HoldShipment,
  SaveUserSettings,
  TrackShipmentRequest,
  UnbookShipment,
  UnHoldShipment,
  UpdateShipment,
  UpdateShipmentLocation
} from '../../../apis/Agency';
import { ErrorToast, successToast } from '../../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PrintIcon from '@mui/icons-material/Print';
import './ShipmentsTable.css';
import { useReactToPrint } from 'react-to-print';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { base_logo_url, base_url } from '../../../apis/constants';
import {
  Badge,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import PanToolTwoToneIcon from '@mui/icons-material/PanToolTwoTone';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ColumnOrderState
} from 'material-react-table';
import { Box, Button, IconButton } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ViewShipment from '../../ViewShipment/ViewShipment';
import NoLogo from '../../../assets/carrier logos/No Logo.png';
import html2pdf from 'html2pdf.js';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Barcode from 'react-barcode';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIsMobile } from '../../../components/IsMobileHook/IsMobileHook';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateRangeFilter } from '../../CustomDatePicker/CustomDatePicker';

const isHighlightedDate = (cellValue, filterValue) => {
  if (!filterValue || !filterValue[0] || !filterValue[1]) return false;

  const [startDate, endDate] = filterValue;
  const rowDate = new Date(cellValue);

  if (isNaN(rowDate)) return false;

  return rowDate >= new Date(startDate) && rowDate <= new Date(endDate);
};

const DateRangeHighlightCell = ({ cell, column }) => {
  const filterValue = column.getFilterValue() || [null, null]; // Safely get the filter value
  const cellValue = cell.getValue(); // Get the cell value

  const isHighlighted = isHighlightedDate(cellValue, filterValue); // Use the utility function

  return (
    <div
      style={{
        backgroundColor: isHighlighted ? 'rgb(255, 203, 127)' : 'transparent',
        padding: '4px',
        borderRadius: '4px',
        width: '50%',
        textAlign: 'center'
      }}
    >
      {cellValue}
    </div>
  );
};

const DateRangeSelector = ({ column, dateField }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDateChange = update => {
    setDateRange(update || [null, null]); // Update local state
    column.setFilterValue(update || [null, null]); // Update column filter value
  };

  return (
    <DatePicker
      isClearable={true}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={handleDateChange}
      placeholderText={`Select ${dateField} range`}
      calendarClassName='custom-calendar'
      dateFormat='MMM d, yyyy' // Ensure the format matches your data
      style={{ width: '120%', borderRadius: '100px' }}
    />
  );
};

function BasicTreeData({ PreviouProps }) {
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const [globalFilter, setGlobalFilter] = useState('');

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 20
  });
  const [value, setValue] = useState(0);

  const UserLoggedInEmail = useSelector(state => state.userDetails.email);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const [actualDeliveryDate, setActualDeliveryDate] = useState(false);
  let TempApiNames = [];
  let TLTempApiNames = [];
  let ParcelTempApiNames = [];
  const [expandedDetail, setExpandedDetail] = useState(false);
  const [DateFilterModal, setDateFilterModal] = useState(false);
  const [shipmentsLoader, setShipmentsLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalShipmentsCount, setTotalShipmentsCount] = useState('');
  const [statusLoader, setStatusLoader] = useState(false);
  const [viewShipmentsData, setViewShipmentsData] = useState([]);
  const [savedShipments, setSavedShipments] = useState('');
  const [tenderedShipments, setTenderedShipments] = useState('');
  const [bookedShipments, setBookedShipments] = useState('');
  const [inTransitShipments, setInTransitShipments] = useState('');
  const [deliveredShipments, setDeliveredShipments] = useState('');
  const [cancelledShipments, setCancelledShipments] = useState('');
  const [carrierApiNames, setCarrierApiNames] = useState([]);
  const [TlCarrierApiNames, setTlCarrierApiNames] = useState([]);
  const [ParcelCarrierApiNames, setParcelCarrierApiNames] = useState([]);
  const [trackShipmentData, setTrackShipmentData] = useState();
  const [holdShipmentRemarks, setHoldShipmentRemarks] = useState('');
  const [updatedProNmb, setUpdatedProNmb] = useState('');
  const [updatedPickNmb, setUpdatedPickNmb] = useState('');
  const [updatedShipmentReason, setUpdatedShipmentReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [markAsDeliverDate, setMarkAsDeliverDate] = useState(new Date());
  const [markDeliverDialog, setMarkDeliverDialog] = useState(false);
  const [openDeliverDialog, setOpenDeliverDialog] = useState(false);
  const [updateShipmentLoader, setUpdateShipmentLoader] = useState(false);
  const [markAsDeliverRowData, setMarkAsDeliverRowData] = useState('');
  const [addUpdateLocationDialog, setAddUpdateLocationDialog] = useState(false);
  const [addUpdateLocationID, setAddUpdateLocationID] = useState('');
  const [addUpdateLocationShipID, setAddUpdateLocationShipID] = useState('');
  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [agencyLocationsLoader, setAgencyLocationsLoader] = useState(false);
  const [agencyLocations, setAgencyLocations] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [clickedRowLocation, setClickedRowLocation] = useState('');
  const [checkLocLoader, setCheckLocLoader] = useState(false);
  const [checkLocIndex, setCheckLocIndex] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [tabChanged, setTabChanged] = useState(false);
  const [applySearch, setApplySearch] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const predefinedOptions = [
    'Option 1: Update Information',
    'Option 2: Correct Error',
    'Option 3: Add Details'
  ];
  const handleRecordsChange = event => {
    // setRecordsPerPage(event.target.value);
    // if (event.target.value < viewShipmentsData.length) {
    setViewShipmentsData([]);
    setHasMore(true);
    // }
    setPagination({
      pageIndex: 1,
      pageSize: event.target.value
    });
  };
  const handleSearchChange = (e, type) => {
    const value = e.target.value;
    type === 'clear' ? setSearchText('') : setSearchText(value);
    if (value === '' || value === undefined) {
      setPagination({ pageIndex: 1, pageSize: 20 });
      setSearchQuery('');
      !applySearch && setViewShipmentsData([]);
      setApplySearch(!applySearch);
    }

    // if (value.length >= 3) {
    //   setSearchQuery(value);
    // } else {
    //   setSearchQuery('');
    // }
  };

  const searchGlobal = () => {
    setSearchQuery(searchText);
    setViewShipmentsData([]);
    setShipmentsLoader(true);
    setApplySearch(!applySearch);
    setPagination({ pageIndex: 1, pageSize: 20 });
  };

  const handleSearchKeyPress = e => {
    if (e.key === 'Enter') {
      searchGlobal();
      // handleTabChange('', 2);
      // setValue(2);
    }
  };

  // const filteredData = useMemo(() => {
  //   if (!searchText) return viewShipmentsData;
  //   return viewShipmentsData.filter(row =>
  //     Object.values(row)
  //       .join(' ')
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase())
  //   );
  // }, [searchQuery, viewShipmentsData]);
  const isInitialRender = useRef(true);
  useEffect(() => {
    // Skip initial render only for specific props
    if (
      isInitialRender.current &&
      (PreviouProps?.props?.selectedTab === 'Booked' ||
        PreviouProps?.props?.selectedTab === 'InTransit' ||
        PreviouProps?.props?.selectedTab === 'Delivered')
    ) {
      isInitialRender.current = false; // Mark initial render as handled
      return;
    }

    handleGetSynchDataRecords(); // Fetch data for other cases
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    globalFilter,
    value,
    searchQuery,
    applySearch
  ]);

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Manage-Shipments'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);
  const updateViewShipmentsData = dataArr => {
    if (dataArr.length === 0) {
      setHasMore(false);
    }
    let temp = [];
    let DestinationData;
    let OriginData;
    for (let i = 0; i < dataArr.length; i++) {
      DestinationData =
        dataArr[i].destinationCompany +
        ' ' +
        dataArr[i].destinationCity +
        ' ' +
        dataArr[i].destinationState +
        ' ' +
        dataArr[i].destinationZipCode;

      OriginData =
        dataArr[i].originCompany +
        ' ' +
        dataArr[i].originCity +
        ' ' +
        dataArr[i].originState +
        ' ' +
        dataArr[i].originZipCode;
      temp.push({
        ...dataArr[i],
        OriginData: OriginData,
        destinationData: DestinationData
      });
    }
    if (pagination.pageIndex > 1) {
      setTimeout(() => {
        setViewShipmentsData(prevData => {
          const combinedData = [...prevData, ...temp];
          const uniqueData = Array.from(
            new Map(combinedData.map(item => [item.id, item])).values()
          );

          return uniqueData;
        });
      }, 0);
    } else {
      setTimeout(() => {
        setViewShipmentsData(temp);
      }, 0);
    }

    setShipmentsLoader(false);
    setIsLoading(false);
  };

  const handleSuccessShipDetails = async (res, newValue) => {
    setTotalShipmentsCount(res.data.pagingInfo.totalCount);
    setTenderedShipments(res.data.pagingInfo.totalTendered);
    setSavedShipments(res.data.pagingInfo.totalSaved);
    setBookedShipments(res.data.pagingInfo.totalBooked);
    setInTransitShipments(res.data.pagingInfo.totalInTransit);
    setDeliveredShipments(res.data.pagingInfo.totalDelivered);
    setCancelledShipments(res.data.pagingInfo.totalCancelled);
    updateViewShipmentsData(res.data.data);
  };
  useEffect(() => {
    if (PreviouProps?.props?.selectedTab === 'Booked') {
      // handleTabChange('Booked', 2);
      setValue(2);
      setTableCol(mtransitTabColumns);
    } else if (PreviouProps?.props?.selectedTab === 'InTransit') {
      // handleTabChange('InTransit', 3);

      setValue(3);
      setTableCol(mtransitTabColumns);
    } else if (PreviouProps?.props?.selectedTab === 'Delivered') {
      // handleTabChange('Delivered', 4);

      setValue(4);
      setTableCol(mtransitTabColumns);
    }
  }, []);

  const handleGetCarrierNames = data => {
    const LtlCarrierArray = data.filter(
      items => items.active && items.cType === 'LTL'
    );
    const TlCarrierArray = data.filter(
      items => items.active && items.cType === 'TL'
    );
    const ParcelCarrierArray = data.filter(
      items => items.active && items.cType === 'Parcel'
    );
    let TempApisEndPoint = [];

    for (let i = 0; i < LtlCarrierArray.length; i++) {
      const CarrierName = LtlCarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < LtlCarrierArray.length; i++) {
      const CarrierName = LtlCarrierArray[i].name;
      const ID = LtlCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === LtlCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + LtlCarrierArray[i].logo;
      TempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    TempApiNames.push({ title: 'Others', carrierID: '0', carrierLogo: '' });
    setCarrierApiNames(TempApiNames);
    for (let i = 0; i < TlCarrierArray.length; i++) {
      const CarrierName = TlCarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < TlCarrierArray.length; i++) {
      const CarrierName = TlCarrierArray[i].name;
      const ID = TlCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === LtlCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + TlCarrierArray[i].logo;
      TLTempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    TLTempApiNames.push({ title: 'Others', carrierID: '0', carrierLogo: '' });
    setTlCarrierApiNames(TLTempApiNames);
    for (let i = 0; i < ParcelCarrierArray.length; i++) {
      const CarrierName = ParcelCarrierArray[i]?.getRateEndPoint?.replace(
        '/',
        ''
      );
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < ParcelCarrierArray.length; i++) {
      const CarrierName = ParcelCarrierArray[i].name;
      const ID = ParcelCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === ParcelCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + ParcelCarrierArray[i].logo;
      ParcelTempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    ParcelTempApiNames.push({
      title: 'Others',
      carrierID: '0',
      carrierLogo: ''
    });
    setParcelCarrierApiNames(ParcelTempApiNames);
  };
  const handleActiveTab = newValue => {
    // if (PreviouProps?.props?.selectedTab === 'Booked') {
    //   return 'Booked';
    // }

    // if (PreviouProps?.props?.selectedTab === 'InTransit') {
    //   return 'InTransit';
    // } else

    if (
      newValue === 0 &&
      PreviouProps?.props?.selectedTab !== 'InTransit' &&
      PreviouProps?.props?.selectedTab !== 'Booked'
    ) {
      return 'Saved';
    } else if (newValue === 1) {
      return 'Tendered';
    } else if (
      newValue === 2 ||
      PreviouProps?.props?.selectedTab === 'Booked'
    ) {
      return 'Booked';
    } else if (
      newValue === 3 ||
      PreviouProps?.props?.selectedTab === 'InTransit'
    ) {
      return 'InTransit';
    } else if (newValue === 4) {
      return 'Delivered';
    } else if (newValue === 5) {
      return 'Cancelled';
    }
  };
  const handleGetSynchDataRecords = newValue => {
    // setShipmentsLoader(true);
    // setViewShipmentsData([]);
    setIsLoading(true);
    const lowercaseFilter = String(globalFilter).toLowerCase();
    GetShipments(
      AgencyID,
      UserLoggedInEmail,
      pagination.pageIndex,
      pagination.pageSize,
      searchQuery,
      handleActiveTab(value)
    )
      .then(res => res.status === 200 && handleSuccessShipDetails(res))
      .catch(err => {
        // setShipmentsLoader(false);
        setHasMore(false);
      });
  };

  useEffect(() => {
    if (UserLoggedInEmail) {
      FetchCurrentUserDetails();
      // GetShipments(
      //   AgencyID,
      //   UserLoggedInEmail,
      //   pagination.pageIndex,
      //   pagination.pageSize,
      //   ''
      // )
      //   .then(res => res.status === 200 && handleSuccessShipDetails(res))
      //   .catch(err => setShipmentsLoader(false));

      GetCarriers(AgencyID)
        .then(res => res.status === 200 && handleGetCarrierNames(res.data.data))
        .catch(err => console.log('Error here...', err));
    }

    // GetAagencyUserDetails(UserLoggedInEmail)
    //   .then(
    //     res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
    //   )
    //   .catch(err => console.log(err, 'err'));
  }, []);

  const history = useHistory();

  // const tableRef = useRef();

  const [onclickSelectedRow, setOnclickSelectedRow] = useState('');
  // const [csvData, setCsvData] = useState([]);
  const [csvData, setCSVData] = useState([]);

  !onclickSelectedRow === null &&
    setTimeout(() => {
      setOnclickSelectedRow(null);
    }, 5000);

  const shipmentStatusColor = shipmentStatus => {
    if (shipmentStatus === 'Saved') {
      return { color: '#448aff', backgroundColor: '#bbdefb' };
    }

    if (shipmentStatus.toUpperCase() === 'HOLD') {
      return { color: '#d50000', backgroundColor: '#ffebee' };
    }
    if (shipmentStatus === 'Booked') {
      return { color: '#9c27b0', backgroundColor: '#f3e5f5' };
    }
    if (shipmentStatus === 'Delivered') {
      return { color: '#1b5e20', backgroundColor: '#c8e6c9' };
    }
    if (shipmentStatus === 'InTransit') {
      return { color: '#e65100', backgroundColor: '#ffe0b2' };
    }
  };

  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });
  // const [, setForceUpdate] = useState(0);

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () =>
  //     componentRef?.current?.childNodes[2]?.childNodes[0]?.children[1]
  //       ?.children[0]
  // });
  const handlePrint = useReactToPrint({
    content: () => {
      if (componentRef?.current) {
        const tableToPrint = componentRef.current
          .querySelector('table')
          .cloneNode(true);

        if (tableToPrint) {
          // Remove unwanted header cells
          const headerRow = tableToPrint.querySelector('thead tr');
          if (headerRow) {
            for (let i = 0; i < 3; i++) {
              if (headerRow.firstElementChild) {
                headerRow.removeChild(headerRow.firstElementChild);
              }
            }
          }

          // Remove specific data cells in each row
          const dataRows = tableToPrint.querySelectorAll('tbody tr');
          dataRows.forEach(row => {
            for (let i = 0; i < 3; i++) {
              if (row.firstElementChild) {
                row.removeChild(row.firstElementChild);
              }
            }
          });

          // Locate and remove filter input elements within header cells
          const headerCells = tableToPrint.querySelectorAll('thead th');
          headerCells.forEach(cell => {
            const filterElements = cell.querySelectorAll(
              'input, select, .MuiFormControl-root'
            );
            filterElements.forEach(filter => filter.remove());
          });

          // Wrap the modified table in a container for printing
          const printContainer = document.createElement('div');
          printContainer.className = 'print-container'; // Apply CSS class if needed
          printContainer.style.margin = '0';
          printContainer.style.padding = '0';
          printContainer.style.overflow = 'hidden';
          printContainer.appendChild(tableToPrint);

          return printContainer;
        }
      }
      return null;
    }
  });

  const handleCsvImport = (formattedData, AllCsvData) => {
    formattedData &&
      history.push('import-csv', {
        csvData: formattedData,
        AllCsvData: AllCsvData
      });
  };
  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file?.type.includes('csv') || file?.type.includes('ms-excel')) {
      parseCsvData(file);
    } else ErrorToast('Select CSV File To Upload');
  };

  const parseCsvData = file => {
    var formattedData;
    Papa.parse(file, {
      complete: result => {
        const AllCsvData = result.data;
        const columns = result.data[0];
        const firstRowData = result?.data[1];

        formattedData = columns.map((column, index) => ({
          columnName: column,
          firstValue: firstRowData && firstRowData[index]
        }));

        setCSVData(formattedData);
        handleCsvImport(formattedData, AllCsvData);
      }
    });
  };

  const fileInputRef = useRef(null);

  const handleActionBtn = (detailsResponse, rowData, row, dialogName) => {
    rowData.details = detailsResponse;
    if (rowData.shipmentType === 'TL') {
      if (rowData.quoteId !== 0 && dialogName === 'Get Quote') {
        GetQuote(rowData.quoteId)
          .then(
            res =>
              res.status === 200 &&
              history.push({
                pathname: '/bookshipmentTL',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: TlCarrierApiNames,
                  apiData: res.data.data,
                  userLocationsArr: []
                }
              })
          )
          .catch(err => console.log(err, 'GetQuoteErr------>'));
      }
      if (dialogName === 'Get Quote') {
        CheckLocation(rowData.shipmentId)
          .then(res => {
            if (res.status === 200 && res.data.data.valid) {
              setShipmentsLoader(false);

              history.push({
                pathname: '/bookshipmentTL',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: ParcelCarrierApiNames,
                  paymentType: res.data.data.paymentType,
                  locationId: res.data.data.locationId
                }
              });
            } else if (res.status === 200 && !res.data.data.valid) {
              setShipmentsLoader(false);

              ErrorToast(
                'No location Mapped against this shipment, please assign or create location!'
              );
              setTimeout(() => {
                history.push({
                  pathname: '/view-locations',
                  search: `?shipmentId=${rowData.shipmentId}`,
                  state: {
                    shipmentId: rowData.shipmentId
                  }
                });
              }, 3000);
            }
          })
          .catch(err => {
            setShipmentsLoader(false);

            ErrorToast(err?.message);
          });
      }

      if (dialogName === 'PickUp') {
        history.push({
          pathname: '/bookshipmentTL',
          state: {
            pickupRowData: JSON.stringify(rowData),
            disptachOpen: 'PickUp',
            carrierApiNames: TlCarrierApiNames
            // // : rowData
          }
        });
      }

      if (dialogName === 'Dispatch') {
        // event.stopPropagation();

        if (rowData?.shipmentStatus.toUpperCase() === 'HOLD') {
          GetShipmentActivity(rowData.shipmentId)
            .then(res => {
              res.status === 200 && res.data.data[0].acrionDetail
                ? ErrorToast(
                    `Shipment cannot be Disptached due to the reason, '${res.data.data[0].acrionDetail}'.`
                  )
                : ErrorToast(
                    'Shipment current status is HOLD, cannot be Disptached!.'
                  );
              setShipmentsLoader(false);
            })
            .catch(err =>
              ErrorToast(
                'Shipment current status is HOLD, cannot be Disptached!'
              )
            );
        } else {
          history.push({
            pathname: '/dispatchshipmentTL',
            state: {
              pickupRowData: JSON.stringify(rowData),
              disptachOpen: 'Dispatch',
              carrierApiNames: TlCarrierApiNames
            }
          });
        }
      }
    } else if (rowData.shipmentType === 'Parcel') {
      if (dialogName === 'Get Quote') {
        // history.push({
        //   pathname: '/bookshipmentParcel',
        //   state: {
        //     pickupRowData: JSON.stringify(rowData),
        //     disptachOpen: 'Get Quote',
        //     carrierApiNames: ParcelCarrierApiNames
        //     // // : rowData
        //   }
        // });
        CheckLocation(rowData.shipmentId)
          .then(res => {
            if (res.status === 200 && res.data.data.valid) {
              setShipmentsLoader(false);
              history.push({
                pathname: '/bookshipmentParcel',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: ParcelCarrierApiNames,
                  paymentType: res.data.data.paymentType,
                  locationId: res.data.data.locationId
                }
              });
            } else if (res.status === 200 && !res.data.data.valid) {
              setShipmentsLoader(false);

              ErrorToast(
                'No location Mapped against this shipment, please assign or create location!'
              );
              setShipmentsLoader(false);

              setTimeout(() => {
                history.push({
                  pathname: '/view-locations',
                  search: `?shipmentId=${rowData.shipmentId}`,
                  state: {
                    shipmentId: rowData.shipmentId
                  }
                });
              }, 3000);
            }
          })
          .catch(err => {
            setShipmentsLoader(false);

            ErrorToast(err?.message);
          });
      }

      if (dialogName === 'PickUp') {
        history.push({
          pathname: '/bookshipmentParcel',
          state: {
            pickupRowData: JSON.stringify(rowData),
            disptachOpen: 'PickUp',
            carrierApiNames: ParcelCarrierApiNames
          }
        });
      }

      if (dialogName === 'Dispatch') {
        // event.stopPropagation();
        if (rowData?.shipmentStatus.toUpperCase() === 'HOLD') {
          GetShipmentActivity(rowData.shipmentId)
            .then(res => {
              res.status === 200 && res.data.data[0].acrionDetail
                ? ErrorToast(
                    `Shipment cannot be Disptached due to the reason, '${res.data.data[0].acrionDetail}'.`
                  )
                : ErrorToast(
                    'Shipment current status is HOLD, cannot be Disptached!.'
                  );
              setShipmentsLoader(false);
            })
            .catch(err =>
              ErrorToast(
                'Shipment current status is HOLD, cannot be Disptached!'
              )
            );
        } else {
          history.push({
            pathname: '/dispatchshipmentParcel',
            state: {
              pickupRowData: JSON.stringify(rowData),
              disptachOpen: 'Dispatch',
              carrierApiNames: ParcelCarrierApiNames
              // assignedProNumber: res.data.data
            }
          });
          // GetNextProNumber(AgencyID, rowData.carrierId)
          //   .then(
          //     res =>
          //       res.status === 200 &&
          //       history.push({
          //         pathname: '/bookshipmentParcel',
          //         state: {
          //           pickupRowData: JSON.stringify(rowData),
          //           disptachOpen: 'Dispatch',
          //           carrierApiNames: ParcelCarrierApiNames,
          //           assignedProNumber: res.data.data
          //         }
          //       })
          //   )
          //   .catch(err =>
          //     history.push({
          //       pathname: '/bookshipmentParcel',
          //       state: {
          //         pickupRowData: JSON.stringify(rowData),
          //         disptachOpen: 'Dispatch',
          //         carrierApiNames: ParcelCarrierApiNames,
          //         assignedProNumber: ''
          //       }
          //     })
          //   );
        }
      }
    } else {
      if (dialogName === 'Get Quote') {
        CheckLocation(rowData.shipmentId)
          .then(res => {
            if (res.status === 200 && res.data.data.valid) {
              setShipmentsLoader(false);

              history.push({
                pathname: '/bookshipment',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: carrierApiNames,
                  paymentType: res.data.data.paymentType,
                  locationId: res.data.data.locationId
                }
              });
            } else if (res.status === 200 && !res.data.data.valid) {
              setShipmentsLoader(false);

              ErrorToast(
                'No location Mapped against this shipment, please assign or create location!'
              );
              setTimeout(() => {
                history.push({
                  pathname: '/view-locations',
                  search: `?shipmentId=${rowData.shipmentId}`,
                  state: {
                    shipmentId: rowData.shipmentId
                  }
                });
              }, 3000);
            }
          })
          .catch(err => {
            setShipmentsLoader(false);

            ErrorToast(err?.message);
          });
      }

      if (dialogName === 'PickUp') {
        history.push({
          pathname: '/bookshipment',
          state: {
            pickupRowData: JSON.stringify(rowData),
            disptachOpen: 'PickUp',
            carrierApiNames: carrierApiNames
          }
        });
      }

      if (dialogName === 'Dispatch') {
        // event.stopPropagation();
        if (rowData?.shipmentStatus.toUpperCase() === 'HOLD') {
          GetShipmentActivity(rowData.shipmentId)
            .then(res => {
              res.status === 200 && res.data.data[0].acrionDetail
                ? ErrorToast(
                    `Shipment cannot be Disptached due to the reason, '${res.data.data[0].acrionDetail}'.`
                  )
                : ErrorToast(
                    'Shipment current status is HOLD, cannot be Disptached!.'
                  );
              setShipmentsLoader(false);
            })
            .catch(err =>
              ErrorToast(
                'Shipment current status is HOLD, cannot be Disptached!'
              )
            );
        } else {
          // GetNextProNumber(AgencyID, rowData.carrierId)
          //   .then(
          //     res =>
          //       res.status === 200 &&
          history.push({
            pathname: '/dispatchshipmentLTL',
            state: {
              pickupRowData: JSON.stringify(rowData),
              disptachOpen: 'Dispatch',
              carrierApiNames: carrierApiNames,
              assignedProNumber: ''
            }
          });
        }
      }
    }
  };
  const handlePickupOpen = (rowData, row, dialogName) => {
    setCheckLocIndex(row.index);
    setShipmentsLoader(true);
    GetShipmentDetails(rowData.shipmentId)
      .then(
        res =>
          res.status === 200 &&
          handleActionBtn(res.data.data, rowData, row, dialogName)
      )
      .catch(err => ErrorToast(err?.message));
    // event.stopPropagation();
  };

  const markAsDeliverRequest = () => {
    const DispatchObj = {
      shipmentId: markAsDeliverRowData.shipmentId
        ? markAsDeliverRowData.shipmentId
        : 0,
      agencyId: AgencyID ? AgencyID : 0,
      type: 1,
      proNumber: markAsDeliverRowData.proNumber
        ? markAsDeliverRowData.proNumber
        : '',
      bolNumber: markAsDeliverRowData.bolNumber
        ? markAsDeliverRowData.bolNumber
        : '',
      refNumber: '',
      pickupNumber: markAsDeliverRowData.pickupNumber
        ? markAsDeliverRowData.pickupNumber
        : '',
      carrierId: markAsDeliverRowData.carrierId
        ? markAsDeliverRowData.carrierId
        : 0,
      carrierName: markAsDeliverRowData.carrierName
        ? markAsDeliverRowData.carrierName
        : '',
      driverName: '',
      vehicleNumber: '',
      phoneNumber: '',
      deliveryDate: markAsDeliverDate
    };
    handleClose();
    DispatchShipment(DispatchObj)
      .then(res => {
        res.status === 200 && successToast('Marked as Deliverd');
        handleTabChange('Delivered', 4);
        // setViewShipmentsData([]);
      })
      .catch(err => {
        alert('asd');
        ErrorToast(JSON.stringify(err?.message));
      });
  };
  const handleMarkDeliver = rowData => {
    // const CallDispatchShipmentApi = () => {
    // setPickUpLoader(true);
    setOpenHoldShipmentDialog(true);
    setMarkDeliverDialog(true);
    setMarkAsDeliverRowData(rowData);

    // };
  };
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              padding: '0px 10px',
              minHeight: '35px',
              maxHeight: '35px'
            },
            '& .MuiInputBase-input': {
              fontSize: '0.9rem',
              padding: '10px'
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.3rem'
            }
          }
        }
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            // height: '20px',
            // width: '20px',
            fontSize: '1rem'
          }
        }
      }
    }
  });
  const mtransitTabColumns = [
    {
      accessorKey: 'mrt-row-actions',
      id: 'mrt-row-actions',
      header: 'mrt-row-actions'
    },
    {
      accessorKey: 'mrt-row-expand',
      id: 'mrt-row-expand',
      header: 'mrt-row-expand'
    },
    // {
    //   accessorKey: 'mrt-row-select',
    //   id: 'mrt-row-select',
    //   header: 'mrt-row-select'
    // },
    {
      // accessorFn: row => `${row.carrierName} ${row.carrierLogo}`,
      accessorKey: 'carrierName',
      id: 'carrierName',
      header: 'Carrier Name',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.carrierName
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          {renderedCellValue && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AvatarGroup sx={{}} spacing='small'>
                <Avatar
                  sx={
                    {
                      // bgcolor: 'ButtonFace',
                      // objectFit: 'contain',
                      // mr: 1
                    }
                  }
                  imgProps={{
                    style: {
                      // height: 25
                    }
                  }}
                  // src={base_logo_url + carrier_logos/${items.logo}`}
                  src={
                    row.original.carrierLogo
                      ? base_logo_url + row.original.carrierLogo
                      : NoLogo
                  }
                />
              </AvatarGroup>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          )}
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
      accessorKey: 'proNumber',
      id: 'proNumber',
      header: 'PRO/Tracking #',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.proNumber
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
      accessorKey: 'bolNumber',
      id: 'bolNumber',
      header: 'BOL #',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.bolNumber
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
      accessorKey: 'soNumber',
      id: 'soNumber',
      header: 'Shipment #',
      size: 10,
      filterFn: (row, colID, filterValue) => {
        return row.original.soNumber
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box>
            <span>{renderedCellValue}</span>
            {/* <br /> */}
          </Box>
        </>
      )
    },
    {
      //   // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'shipmentStatus',
      id: 'shipmentStatus',
      header: 'Shipment Status',
      size: 50,
      // filterFn: (row, colID, filterValue) => {
      //   return row?.original?.shipmentStatus
      //     .toLowerCase()
      //     .includes(filterValue?.toLowerCase());
      // },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box>
            <Chip
              sx={shipmentStatusColor(row?.original?.shipmentStatus)}
              label={renderedCellValue}
            />
          </Box>
        </>
      )
    },

    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'shipmentType',
      id: 'shipmentType',
      header: 'Shipment Mode',
      size: 70,
      filterFn: (row, colID, filterValue) => {
        return row.original.shipmentType
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              sx={{ backgroundColor: '#E1F5FE', color: '#01579B' }}
              label={renderedCellValue}
            />
          </Box>
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'createdOn',
      id: 'createdOn',
      header: 'Created On',
      size: 70,
      filterFn: (row, columnId, filterValue) => {
        if (!filterValue || !filterValue[0] || !filterValue[1]) return true;

        const rowDate = new Date(row.original.createdOn);
        if (isNaN(rowDate)) return false;

        const [startDate, endDate] = filterValue;
        return rowDate >= startDate && rowDate <= endDate;
      },
      Filter: ({ column }) => (
        <DateRangeSelector column={column} dateField='Created On' />
      ),
      Cell: DateRangeHighlightCell
    },
    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'quoteNumber',
      id: 'quoteNumber',
      header: 'Quote Number',
      size: 70,
      filterFn: (row, colID, filterValue) => {
        return row.original.quoteNumber
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {renderedCellValue}
          </Box>
        </>
      )
    },
    // {
    //   accessorKey: 'pickupDate',
    //   id: 'pickupDate',

    //   header: 'Requested Pickup Date',
    //   size: 250,
    //   filterFn: (row, colID, filterValue) => {
    //     return row.original.pickupDate
    //       .toLowerCase()
    //       .includes(filterValue.toLowerCase());
    //   },
    //   sortingFn: (rowA, rowB, columnId) => {
    //     const dateA = new Date(rowA.original.pickupDate);
    //     const dateB = new Date(rowB.original.pickupDate);
    //     return dateA - dateB; // Ascending order
    //   },
    //   Filter: ({ column, table }) => {
    //     return (
    //       <DatePicker
    //         selected={startDate}
    //         onChange={onChange}
    //         startDate={startDate}
    //         endDate={endDate}
    //         selectsRange
    //         inline
    //       />
    //     );
    //     //   <ThemeProvider theme={theme}>
    //     //     <LocalizationProvider dateAdapter={AdapterDayjs}>
    //     //       <DatePicker
    //     //         value={selectedDate}
    //     //         onChange={newDate => {
    //     //           if (!newDate) {
    //     //             // Handle cleared or invalid date (remove filter)
    //     //             table.setColumnFilters(prevFilters =>
    //     //               prevFilters.filter(filter => filter.id !== column.id)
    //     //             );
    //     //             return;
    //     //           }

    //     //           // Format the date as "Nov 22, 2024"
    //     //           const formattedDate = dayjs(newDate).format('MMM D, YYYY'); // Format as "Nov 22, 2024"

    //     //           // Safely set the column filter
    //     //           table.setColumnFilters(prevFilters => [
    //     //             ...prevFilters.filter(filter => filter.id !== column.id),
    //     //             { id: column.id, value: formattedDate }
    //     //           ]);
    //     //         }}
    //     //         renderInput={params => (
    //     //           <TextField
    //     //             {...params}
    //     //             variant='outlined'
    //     //             size='small'
    //     //             placeholder='Filter by Date'
    //     //           />
    //     //         )}
    //     //       />
    //     //     </LocalizationProvider>
    //     //   </ThemeProvider>
    //     // );
    //   },
    //   Cell: ({ renderedCellValue, row }) => (
    //     <>
    //       <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //         <span>{renderedCellValue}</span>
    //         <br />
    //       </Box>
    //     </>
    //   )
    // },
    {
      accessorKey: 'pickupDate',
      id: 'pickupDate',
      header: 'Requested Pickup Date',
      size: 250,
      filterFn: (row, columnId, filterValue) => {
        if (!filterValue || !filterValue[0] || !filterValue[1]) return true;

        const rowDate = new Date(row.original.pickupDate);
        if (isNaN(rowDate)) return false;

        const [startDate, endDate] = filterValue;
        return rowDate >= startDate && rowDate <= endDate;
      },
      Filter: ({ column }) => (
        <DateRangeSelector column={column} dateField='Pickup Date' />
      ),
      Cell: DateRangeHighlightCell
    },
    {
      accessorKey: 'actualPickupDate',
      id: 'actualPickupDate',
      header: 'Actual Pickup Date',
      size: 250,
      filterFn: (row, columnId, filterValue) => {
        if (!filterValue || !filterValue[0] || !filterValue[1]) return true;

        const rowDate = new Date(row.original.deliveryDate);
        if (isNaN(rowDate)) return false;

        const [startDate, endDate] = filterValue;
        return rowDate >= startDate && rowDate <= endDate;
      },
      Filter: ({ column }) => (
        <DateRangeSelector column={column} dateField='Actual Pickup Date' />
      ),
      Cell: DateRangeHighlightCell
    },
    {
      accessorKey: 'deliveryDate',
      id: 'deliveryDate',
      header: 'Est Delivery Date',
      size: 250,
      filterFn: (row, columnId, filterValue) => {
        if (!filterValue || !filterValue[0] || !filterValue[1]) return true;

        const rowDate = new Date(row.original.deliveryDate);
        if (isNaN(rowDate)) return false;

        const [startDate, endDate] = filterValue;
        return rowDate >= startDate && rowDate <= endDate;
      },
      Filter: ({ column }) => (
        <DateRangeSelector column={column} dateField='Est Delivery Date' />
      ),
      Cell: DateRangeHighlightCell
    },
    {
      accessorKey: 'actualDeliveryDate',
      id: 'actualDeliveryDate',
      header: 'Actual Delivery Date',
      size: 250,
      filterFn: (row, columnId, filterValue) => {
        if (!filterValue || !filterValue[0] || !filterValue[1]) return true;

        const rowDate = new Date(row.original.actualDeliveryDate);
        if (isNaN(rowDate)) return false;

        const [startDate, endDate] = filterValue;
        return rowDate >= startDate && rowDate <= endDate;
      },

      Filter: ({ column }) => (
        <DateRangeSelector column={column} dateField='Actual Delivery Date' />
      ),
      Cell: DateRangeHighlightCell
    },

    {
      accessorKey: 'originCompany',
      id: 'originCompany', //id is still required when using accessorFn instead of accessorKey
      header: 'Origin Company',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.originCompany
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'originZipCode',
      id: 'originZipCode', //id is still required when using accessorFn instead of accessorKey
      header: 'Origin Zip',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.originZipCode
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'originCity',
      id: 'originCity', //id is still required when using accessorFn instead of accessorKey
      header: 'Origin City',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.originCity
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },

    {
      accessorKey: 'originState',
      id: 'originState', //id is still required when using accessorFn instead of accessorKey
      header: 'Origin State',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.originState
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'destinationCompany',
      id: 'destinationCompany', //id is still required when using accessorFn instead of accessorKey
      header: 'Destination Company',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.destinationCompany
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'destinationZipCode',
      id: 'destinationZipCode', //id is still required when using accessorFn instead of accessorKey
      header: 'Destination Zip',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.destinationZipCode
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'destinationCity',
      id: 'destinationCity', //id is still required when using accessorFn instead of accessorKey
      header: 'Destination City',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.destinationCity
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'destinationState',
      id: 'destinationState', //id is still required when using accessorFn instead of accessorKey
      header: 'Destination State',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.destinationState
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billToCompany',
      id: 'billToCompany', //id is still required when using accessorFn instead of accessorKey
      header: 'Bill To Company ',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.billToCompany
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue()}
          </Box>
        </>
      )
    },
    {
      accessorKey: 'shipmentCharges',
      id: 'shipmentCharges',
      header: 'Carrier Total Rate',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        const shipmentCharges = parseFloat(row.original.shipmentCharges); // Convert to a number
        if (isNaN(shipmentCharges)) return false; // Skip rows with invalid numbers

        // Check if the filterValue is part of the shipmentCharges string representation
        return shipmentCharges.toString().includes(filterValue);
      },
      Cell: ({ row, table }) => {
        const rawValue = row.original.shipmentCharges; // Get raw, unformatted value
        const formattedValue = parseFloat(rawValue); // Parse the raw value

        const filterValue =
          table.getColumn('shipmentCharges').getFilterValue() || ''; // Get the current filter value
        const isHighlighted =
          filterValue && formattedValue.toString().includes(filterValue);

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span
              style={
                isHighlighted ? { backgroundColor: 'rgb(255, 203, 127)' } : {}
              }
            >
              {!isNaN(formattedValue)
                ? `$${formattedValue.toFixed(2)}`
                : '$0.00'}
            </span>
          </Box>
        );
      }
    },

    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'freightTerms',
      id: 'freightTerms',
      header: <div style={{ whiteSpace: 'nowrap' }}>Carrier Freight Terms</div>,
      size: 70,
      filterFn: (row, colID, filterValue) => {
        return row.original.freightTerms
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          {row.original.shipmentType !== 'TL' && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue === 'C' ? (
                <Chip
                  sx={{ backgroundColor: '#E8F5E9', color: '#1B5E20' }}
                  label={renderedCellValue}
                />
              ) : renderedCellValue === 'PP' ? (
                <Chip
                  sx={{ backgroundColor: '#E1F5FE', color: '#01579B' }}
                  label={renderedCellValue}
                />
              ) : renderedCellValue === 'P' ? (
                <Chip
                  sx={{ backgroundColor: '#FCE4EC', color: '#B71C1C' }}
                  label={renderedCellValue}
                />
              ) : (
                <Chip
                  sx={{ backgroundColor: '#00000014', color: '#00000099' }}
                  label={renderedCellValue}
                />
              )}
            </Box>
          )}
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.details[0].pallets}`,
      accessorKey: 'totalGrossWeight',
      id: 'totalGrossWeight',
      header: 'Total Gross Weight',
      size: 50,
      // filterFn: (row, colID, filterValue) => {
      //   return row.original.customerId
      //     .toLowerCase()
      //     .includes(filterValue.toLowerCase());
      // },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },

    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'paymentTerms',
      id: 'paymentTerms',
      header: (
        <div style={{ whiteSpace: 'nowrap' }}>Customer Freight Terms</div>
      ),
      size: 70,
      filterFn: (row, colID, filterValue) => {
        return row.original.paymentTerms
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },

    {
      accessorKey: 'shippingNotes',
      id: 'shippingNotes',
      header: 'Shipping Notes',
      size: 10,

      // Pre-process the value for consistent filtering
      filterFn: (row, colID, filterValue) => {
        const rawValue = row.original?.shippingNotes || '';
        const plainText = rawValue.replace(/<\/?[^>]+(>|$)|&nbsp;/g, '').trim(); // Strip HTML tags
        return plainText.toLowerCase().includes(filterValue.toLowerCase());
      },

      // Render pre-processed value with conditional background styling
      Cell: ({ row, table }) => {
        // Access the current filter value for this column
        const filterValue =
          table
            .getState()
            .columnFilters.find(filter => filter.id === 'shippingNotes')
            ?.value || '';

        // Safely access the original value
        const rawValue = row.original?.shippingNotes || '';
        const plainText = rawValue.replace(/<\/?[^>]+(>|$)|&nbsp;/g, '').trim(); // Strip HTML tags only once

        // Check if the value matches the filter
        const isFiltered =
          filterValue &&
          plainText.toLowerCase().includes(filterValue.toLowerCase());

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: isFiltered
                ? 'rgb(255, 203, 127)'
                : 'transparent', // Highlight if filtered
              padding: '4px',
              borderRadius: '4px'
            }}
          >
            <span>{plainText}</span>
          </Box>
        );
      }
    },
    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'locationName',
      id: 'locationName',
      header: 'Location Name',
      size: 10,
      filterFn: (row, colID, filterValue) => {
        return row.original.locationName
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
      accessorKey: 'clientPONumber',
      id: 'clientPONumber',
      header: 'PO#',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.clientPONumber
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.details[0].pallets}`,
      accessorKey: 'customerId',
      id: 'customerId',
      header: 'Customer ID',
      size: 50,
      filterFn: (row, colID, filterValue) => {
        return row.original.customerId
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{renderedCellValue}</span>
            <br />
          </Box>
        </>
      )
    },
    {
      // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
      accessorKey: 'shipmentTypeRule',
      id: 'shipmentTypeRule',
      header: 'Shipment Type',
      size: 70,
      filterFn: (row, colID, filterValue) => {
        return row.original.shipmentTypeRule
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
      Cell: ({ renderedCellValue, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {renderedCellValue}
          </Box>
        </>
      )
    }
  ];
  // tabs state
  const handleFilteredTableData = () => {
    if (searchQuery) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus.toLowerCase() === 'saved' ||
          items.shipmentStatus.toLowerCase() === 'unbook' ||
          items.shipmentStatus.toLowerCase() === 'tendered' ||
          items.shipmentStatus.toLowerCase() === 'booked' ||
          items.shipmentStatus.toLowerCase() === 'hold' ||
          items.shipmentStatus.toLowerCase() === 'delivered' ||
          items.shipmentStatus.toLowerCase() === 'intransit' ||
          items.shipmentStatus.toLowerCase() === 'cancelled'
      );
      return FilteredShipments;
    }

    if (value === 0) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus.toLowerCase() === 'saved' ||
          items.shipmentStatus.toLowerCase() === 'unbook'
      );
      return FilteredShipments;
    } else if (value === 1) {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'tendered'
      );
      return FilteredShipments;
    } else if (value === 2) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus.toLowerCase() === 'booked' ||
          items.shipmentStatus.toLowerCase() === 'hold'
      );

      return FilteredShipments;
    } else if (value === 3) {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'intransit'
      );

      return FilteredShipments;
    } else if (value === 4) {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'delivered'
      );

      return FilteredShipments;
    } else {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'cancelled'
      );
      return FilteredShipments;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const [tableCol, setTableCol] = useState(mtransitTabColumns);

  const handleFilterdTableCols = value => {
    const filteredCol = mtransitTabColumns.filter(colNames => {
      return !colNames.accessorKey.includes('mrt');
    });

    if (value === 0) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'carrierName' &&
          col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          col.accessorKey !== 'shipmentCharges' &&
          col.accessorKey !== 'deliveryDate' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualPickupDate' &&
          col.accessorKey !== 'pickupDate' &&
          col.accessorKey !== 'quoteNumber' &&
          col.accessorKey !== 'shipmentTypeRule'
        );
      });
      setTableCol(col);
    } else if (value === 1 || value === 5) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'carrierName' &&
          col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          col.accessorKey !== 'shipmentCharges' &&
          col.accessorKey !== 'deliveryDate' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualPickupDate' &&
          col.accessorKey !== 'pickupDate' &&
          col.accessorKey !== 'quoteNumber'
        );
      });
      setTableCol(col);
    } else if (value === 2) {
      const col = filteredCol.filter(col => {
        return (
          // col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          // col.accessorKey !== 'calculatedEstimatedDate' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualPickupDate'
        );
      });
      setTableCol(col);
    } else if (value === 3) {
      const col = filteredCol.filter(col => {
        return (
          // col.accessorKey !== 'deliveryDate' &&
          col.accessorKey !== 'pickupDate' &&
          col.accessorKey !== 'actualDeliveryDate'
        );
      });
      setTableCol(col);
    } else if (value === 4) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'pickupDate'
          // && col.accessorKey !== 'deliveryDate'
        );
      });
      setTableCol(col);
    } else {
      setTableCol(filteredCol);
    }
  };

  const handleTabChange = (event, newValue) => {
    setHasMore(true);
    // setViewShipmentsData([]);
    setShipmentsLoader(true);
    setExpandedDetail(true);
    setValue(newValue);
    setPagination({
      pageIndex: 1,
      pageSize: 20
    });
    handleFilterdTableCols(newValue);
  };

  useEffect(() => {
    handleFilterdTableCols(value);
  }, [value]);
  // const TableTitle = () => {
  //   console.log(value, 'valuevalue');
  //   if (value === 0) {
  //     return msavedTabColumns;
  //   } else if (value === 1) {
  //     return mbookedTabColumns;
  //   } else {
  //     return mtransitTabColumns;
  //   }
  // };

  // useEffect(() => {
  //   TableTitle();
  // }, [value]);

  const [openHoldShipmentDialog, setOpenHoldShipmentDialog] = useState(false);
  const [openPrintPDfDialog, setOpenPrintPDfDialog] = useState(false);
  const [numberOfPrints, setNumberOfPrints] = useState(2);
  const [numberOfPrintsColmnVise, setNumberOfPrintsColmnVise] = useState(1);
  const [openPrintPDfDialogData, setOpenPrintPDfDialogData] = useState('');
  const [shipmentDialogName, setShipmentDialogName] = useState('');
  const [rowData, setrowData] = useState('');

  const handleClickOpen = (dialogName, row, rowData) => {
    // rowData.stopPropagation();
    setOpenHoldShipmentDialog(true);
    // setMarkDeliverDialog(false);
    setrowData(row);
    if (dialogName === 'Hold') {
      setShipmentDialogName('Hold Shipment');
    } else if (dialogName === 'EditRow') {
      setShipmentDialogName('Update Shipment');
    } else setShipmentDialogName('Un-Book Shipment');
  };

  const handleClickCancel = row => {
    // rowData.stopPropagation();
    setOpenHoldShipmentDialog(true);
    setrowData(row.original);
    setShipmentDialogName('Cancel Shipment');
  };

  const handleClose = () => {
    setUpdateShipmentLoader(false);

    setOpenHoldShipmentDialog(false);
    setMarkDeliverDialog(false);
    setHoldShipmentRemarks('');
    setUpdatedProNmb('');
    setUpdatedPickNmb('');
    setUpdatedShipmentReason('');
    setShipmentDialogName('');
  };

  const handleSuccessTrackShip = apiRes => {
    setTrackShipmentData(apiRes);
    history.push({
      pathname: '/track-shipment',
      state: { trackShipmentData: apiRes }
    });
  };

  const handleSuccessUnBookedShipment = (ApiRes, holdShipmentRemarks) => {
    handleClose();
    successToast(
      `Shipment has been UnBooked with remarks, "${holdShipmentRemarks}"`
    );
    handleTabChange('UnBooked', 0);
    setHoldShipmentRemarks('');
  };

  const handleSuccessCanceledShipment = (ApiRes, holdShipmentRemarks) => {
    handleClose();
    successToast(
      `Shipment has been Canceled with remarks, "${holdShipmentRemarks}"`
    );
    handleTabChange('Cancelled', 5);
    setHoldShipmentRemarks('');
  };

  const handleErrorUnBookedShipment = ApiError => {
    handleClose();
    ErrorToast('Something Went Wrong!');
  };

  const handleErrorCanceledShipment = ApiError => {
    handleClose();
    ErrorToast('Something Went Wrong!');
  };

  const handleUnBookShipmentRequest = (event, selectedRowData) => {
    // event.stopPropagation();

    const UnBookShipmentObj = {
      shipmentId: rowData?.shipmentId ? rowData?.shipmentId : 0,
      remarks: holdShipmentRemarks,
      action: 0
    };
    UnbookShipment(UnBookShipmentObj)
      .then(
        res =>
          res.status === 200 &&
          handleSuccessUnBookedShipment(res, holdShipmentRemarks)
      )
      .catch(err => handleErrorUnBookedShipment(err));
  };

  const handleUpdateShipment = () => {
    setUpdateShipmentLoader(true);
    const shipObj = {
      shipmentId: rowData.shipmentId ? rowData.shipmentId : 0,
      proNumber: updatedProNmb ? updatedProNmb : '',
      pickupNumber: updatedPickNmb ? updatedPickNmb : '',
      reason: updatedShipmentReason ? updatedShipmentReason : ''
    };
    UpdateShipment(shipObj)
      .then(res => {
        res.status === 200 && handleClose();
        setShipmentsLoader(false);
        setIsLoading(false);
        handleGetSynchDataRecords();
        successToast(
          `Shipment has been updated with reason, "${updatedShipmentReason}"`
        );
      })
      .catch(err => {
        handleErrorUnBookedShipment(err);
        setUpdatedProNmb('');
        setUpdatedPickNmb('');
        setUpdatedShipmentReason('');
        console.log(err, 'Update Api Error________>>>>>>>>');
      });
  };

  const handleCancelShipmentRequest = (event, selectedRowData) => {
    // event.stopPropagation();

    const UnBookShipmentObj = {
      shipmentId: rowData?.shipmentId ? rowData?.shipmentId : 0,
      remarks: holdShipmentRemarks,
      action: 0
    };
    CancelShipment(UnBookShipmentObj)
      .then(
        res =>
          res.status === 200 &&
          handleSuccessCanceledShipment(res, holdShipmentRemarks)
      )
      .catch(err => handleErrorCanceledShipment(err));
  };

  // hold- shipment
  const handleSuccessHoldShipment = (ApiRes, holdShipmentRemarks) => {
    setStatusLoader(false);
    handleClose();
    successToast(
      `Shipment has been Hold with remarks, "${holdShipmentRemarks}".`
    );
    setHoldShipmentRemarks('');
  };

  const handleErrorHoldShipment = ApiError => {
    setStatusLoader(false);
    handleClose();
    ErrorToast('Something Went Wrong!');
  };

  const handleHoldShipmentRequest = (event, selectedRowData) => {
    // setStatusLoader(true);
    // event.stopPropagation();
    const HoldShipmentObj = {
      shipmentId: rowData?.shipmentId ? rowData?.shipmentId : 0,
      remarks: holdShipmentRemarks,
      action: 0
    };
    HoldShipment(HoldShipmentObj)
      .then(
        res =>
          res.status === 200 &&
          handleSuccessHoldShipment(res, holdShipmentRemarks)
      )
      .catch(err => handleErrorHoldShipment(err));
  };

  // Un-Hold shipment
  const handleSuccessUnHoldShipment = ApiRes => {
    handleGetSynchDataRecords();
    successToast('Shipment has been UnHold!');
  };

  const handleUnHoldShipmentRequest = selectedRowData => {
    // event.stopPropagation();
    const UnHoldShipmentObj = {
      shipmentId: selectedRowData?.shipmentId ? selectedRowData?.shipmentId : 0,
      remarks: '',
      action: 0
    };
    UnHoldShipment(UnHoldShipmentObj)
      .then(res => res.status === 200 && handleSuccessUnHoldShipment(res))
      .catch(err => console.log(err, 'UnHoldShipmentErr'));
  };

  // track shipment
  const handleTrackShipmentRequest = (selectedRowData, event) => {
    // event.stopPropagation();
    const obj = {
      carrierInfo: {
        id: selectedRowData?.carrierId ? selectedRowData.carrierId : 0,
        name: selectedRowData?.carrierName ? selectedRowData.carrierName : '',
        logo: ''
      },
      proNumber: selectedRowData?.proNumber,
      shipmentId: selectedRowData?.shipmentId,
      locationId: selectedRowData?.locationId,
      shipmentType: selectedRowData?.shipmentType,
      agencyId: AgencyID ? AgencyID : 0
    };

    TrackShipmentRequest(obj)
      .then(res => res.status === 200 && handleSuccessTrackShip(res.data))
      .catch(err => ErrorToast(err?.message));
  };

  // Save the column filters to storage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem('columnFilters', JSON.stringify(columnFilters));
  // }, [columnFilters]);

  const GetSuccessUserDetails = apiRes => {
    setColumnOrder(
      JSON.parse(
        apiRes.data?.userSettings?.SHIPMENT_GRID_ORDER?.filter(
          items => items !== 'mrt-row-select'
        )
      )
    );

    setColumnVisibility(
      JSON.parse(apiRes.data.userSettings.SHIPMENT_GRID_COLUMNS)
    );
  };
  const FetchCurrentUserDetails = () => {
    GetAagencyUserDetails(UserLoggedInEmail)
      .then(
        res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
      )
      .catch(err => console.log(err));
  };
  const handleExpandedChange = newExpanded => {
    const expandedRows = Object.keys(newExpanded).filter(
      key => newExpanded[key]
    );

    // Ensure only one row is expanded at a time.
    if (expandedRows.length > 0) {
      setExpandedRow(expandedRows[0]); // Set the first expanded row's ID or index
    } else {
      setExpandedRow(null); // No row is expanded
    }
  };
  const handleViewShipments = (row, prop) => {
    const isExpanded = row.getIsExpanded();
    setExpandedDetail(false);
    return (
      <Box>
        <ViewShipment
          rowData={row.original}
          disptachOpen={'View'}
          handleTrackShipmentRequest={handleTrackShipmentRequest}
        />
      </Box>
    );
  };
  const isFirstRender = useRef(true);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    tableCol.map(c => c.accessorKey) //array of column ids
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // sessionStorage.setItem(
    //   'mrt_columnOrder_table_1',
    //   JSON.stringify(columnOrder)
    // );

    const userSettingsObj = {
      loginId: UserLoggedInEmail,
      type: 'SHIPMENT_GRID_ORDER',
      value: JSON.stringify(columnOrder)
    };
    SaveUserSettings(userSettingsObj)
      .then(res => res.status === 200 && console.log(res, 'resres'))
      .catch(err => console.log(err, 'err'));
  }, [columnOrder, UserLoggedInEmail, SaveUserSettings]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // sessionStorage.setItem(
    //   'mrt_columnVisibility_table_1',
    //   JSON.stringify(columnVisibility)
    // );
    const userSettingsObj2 = {
      loginId: UserLoggedInEmail,
      type: 'SHIPMENT_GRID_COLUMNS',
      value: JSON.stringify(columnVisibility)
    };
    SaveUserSettings(userSettingsObj2)
      .then(res => res.status === 200 && console.log(res, 'resres'))
      .catch(err => console.log(err, 'err'));
  }, [columnVisibility, UserLoggedInEmail, SaveUserSettings]);

  const generatePDF = async () => {
    const element = document.getElementById('pdf-content');

    const pageSize = {
      width: 1150,
      height: 1150
    };

    const pdfOptions = {
      // margin: 1,
      filename: `${openPrintPDfDialogData.carrierName}_label.pdf`,
      // filename: 'label.pdf',
      // image: { type: 'jpeg', quality: 1.0 },
      html2canvas: {
        scale: 2, // Adjust scale as needed
        logging: true // Enable logging if needed
      },
      jsPDF: {
        unit: 'pt',
        format: [pageSize.width, pageSize.height],
        // format: 'a4',
        orientation: 'portrait'
      }
    };

    await html2pdf(element, pdfOptions);
  };
  const printData = rowData => {
    GetShipmentDetails(rowData.shipmentId)
      .then(res => {
        if (res.status === 200) {
          if (rowData.shipmentType === 'Parcel') {
            window.open(rowData.dispatchInput1, '_blank');
          } else {
            rowData.details = res.data.data;
            setOpenPrintPDfDialog(true);
            setOpenPrintPDfDialogData(rowData);
          }
        }
      })
      .catch(err => ErrorToast(err?.message));
  };

  const handlePDFPrintClose = () => {
    setOpenPrintPDfDialog(false);
    setNumberOfPrintsColmnVise(1);
  };

  const handleDateFilterModalClose = () => {
    setDateFilterModal(false);
  };

  const handleCloseAddUpdateLocation = () => {
    setAddUpdateLocationDialog(false);
    setUserLocations([]);
    setAddUpdateLocationID('');
    setAddUpdateLocationShipID('');
  };
  const handleAdminLocationChange = (event, newValue) => {
    setUserLocations(newValue);
    setAddUpdateLocationID(newValue?.id);
    // setValidationErrors({
    //   ...validationErrors,
    //   locationsError: null
    // });
  };
  const handleOpenAddUpdateLocReq = () => {
    const locatioObj = {
      shipmentId: addUpdateLocationShipID,
      locationId: addUpdateLocationID
    };
    UpdateShipmentLocation(locatioObj)
      .then(res => {
        res.status === 200 && handleCloseAddUpdateLocation();
        handleGetSynchDataRecords();
        successToast('Location Updated Successfully!');
      })
      .catch(err => {
        handleCloseAddUpdateLocation();
        ErrorToast(err?.message);
      });
  };
  const tableContainerRef = useRef(null);

  const loadMoreData = () => {
    setPagination(prevPage => prevPage + 1);
  };
  const lastScrollTop = useRef(0);

  useEffect(() => {
    const container = tableContainerRef.current;

    const handleScroll = () => {
      if (!container || isLoading || !hasMore) return;

      const { scrollTop, clientHeight, scrollHeight } = container;

      // Check if the user is scrolling down and near the bottom
      if (scrollTop > lastScrollTop.current) {
        if (
          scrollTop + clientHeight >= scrollHeight - 150 &&
          handleTotalRecords() > 20
        ) {
          setPagination(prevPagination => ({
            ...prevPagination,
            pageIndex: prevPagination.pageIndex + 1 // Increment pageIndex to load more data
          }));
        }
      }

      lastScrollTop.current = scrollTop;
    };

    // Attach the scroll event to the container
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    // Cleanup on component unmount or re-render
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading, hasMore]);

  const handleTotalRecords = () => {
    if (value === 0) {
      return savedShipments;
    }
    if (value === 1) {
      return tenderedShipments;
    }

    if (value === 2) {
      return bookedShipments;
    }
    if (value === 3) {
      return inTransitShipments;
    }
    if (value === 4) {
      return deliveredShipments;
    }
    if (value === 5) {
      return cancelledShipments;
    }
  };

  const isMobile = useIsMobile();
  return (
    <>
      <Dialog
        sx={{ minWidth: 400 }}
        open={DateFilterModal}
        onClose={handleDateFilterModalClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Date Filter</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <TextField
              // value={holdShipmentRemarks}
              onChange={handleDateFilterModalClose}
              variant='standard'
              type='date'
              // fullWidth
              sx={{ width: '13vw' }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleDateFilterModalClose}>
            Cancel
          </Button>
          <Button
            variant='contained'

            // autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={
          isMobile
            ? {}
            : {
                style: {
                  minWidth: '20vw',
                  height: 'auto',
                  maxHeight: '70vh'
                }
              }
        }
        sx={{ minWidth: 400 }}
        open={openHoldShipmentDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {markDeliverDialog
            ? 'Are you sure to mark as Deilvered ?'
            : shipmentDialogName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            style={{ height: openDeliverDialog ? 300 : 'auto' }}
          >
            {shipmentDialogName === 'Update Shipment' ? (
              <Box sx={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
                <TextField
                  value={updatedProNmb}
                  onChange={e => setUpdatedProNmb(e.target.value)}
                  variant='standard'
                  label='New PRO# '
                  placeholder='Enter the new PRO# to be assigned. '
                  type='text'
                  required
                  sx={{ width: '30vw' }}
                  helperText={`Current PRO#: ${rowData.proNumber}`}
                />
                <TextField
                  value={updatedPickNmb}
                  onChange={e => setUpdatedPickNmb(e.target.value)}
                  variant='standard'
                  label='New Pickup# '
                  placeholder='Enter the new Pickup# to be assigned. '
                  type='text'
                  required
                  sx={{ width: '30vw' }}
                  helperText={`Current Pickup#: ${rowData.pickupNumber}`}
                />
                {/* <TextField
                  select
                  label='Reason for Change'
                  value={updatedShipmentReason}
                  onChange={e => setUpdatedShipmentReason(e.target.value)} // Update state
                  variant='standard'
                  fullWidth
                  sx={{ mb: 0 }}
                >
                  {predefinedOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem value='custom'>Other (Specify)</MenuItem>
                </TextField> */}

                {/* {updatedShipmentReason === 'custom' && ( */}
                <TextField
                  label='Reason for Change'
                  value={updatedShipmentReason}
                  onChange={e => setUpdatedShipmentReason(e.target.value)} // Handle custom input
                  multiline
                  maxRows={4}
                  variant='standard'
                  fullWidth
                />
                {/* )} */}
              </Box>
            ) : !markDeliverDialog ? (
              <TextField
                value={holdShipmentRemarks}
                onChange={e => setHoldShipmentRemarks(e.target.value)}
                variant='standard'
                label='Remarks'
                multiline
                maxRows={4}
                type='text'
                sx={{ width: '21vw' }}
              />
            ) : (
              <DateRangeFilter
                styleProp={'shipmentsTable'}
                selected={markAsDeliverDate}
                onChange={date => setMarkAsDeliverDate(date.date)}
                placeholderText='Select Delivery Date'
                markingDone={true}
                setOpenDeliverDialog={setOpenDeliverDialog}
                style={{ width: 20 }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={
              markDeliverDialog
                ? markAsDeliverRequest
                : shipmentDialogName === 'Hold Shipment'
                ? handleHoldShipmentRequest
                : shipmentDialogName === 'Cancel Shipment'
                ? handleCancelShipmentRequest
                : shipmentDialogName === 'Update Shipment'
                ? handleUpdateShipment
                : handleUnBookShipmentRequest
            }
            // autoFocus
          >
            {markDeliverDialog ? (
              'Confirm'
            ) : updateShipmentLoader ? (
              <CircularProgress sx={{ color: 'white' }} size={22} />
            ) : (
              shipmentDialogName
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ minWidth: 700 }}
        open={addUpdateLocationDialog}
        onClose={handleCloseAddUpdateLocation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Update Location</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {agencyLocationsLoader ? (
              <Box
                sx={{
                  width: '27vw',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress size={25} />
              </Box>
            ) : (
              <Autocomplete
                sx={{ width: '27vw' }}
                size='small'
                value={agencyLocations.find(
                  opt => opt.name === clickedRowLocation
                )}
                onChange={(event, newValue) =>
                  handleAdminLocationChange(event, newValue)
                }
                renderOption={(PreviousProps, option, { selected }) => (
                  <li {...PreviousProps}>
                    {`${option.name}: ${option.city}  ${
                      option.state ? option.state : option.stateOrProvinceCode
                    }  ${option.postalCode}`}
                  </li>
                )}
                getOptionLabel={
                  option =>
                    // option.city ?
                    `${option.city}  ${
                      option.state ? option.state : option.stateOrProvinceCode
                    }  ${option.postalCode}`
                  // : 'Select location'
                }
                options={agencyLocations}
                renderInput={params => (
                  <TextField
                    required
                    label='Locations'
                    variant='standard'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 13 }
                    }}
                    // error={Boolean(validationErrors['locationsError'])}
                    // helperText={validationErrors['locationsError']}
                  />
                )}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            variant='outlined'
            onClick={handleCloseAddUpdateLocation}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOpenAddUpdateLocReq}
            // disabled={addUpdateLocationID ? false : true}
            size='small'
            variant='contained'
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {!openHoldShipmentDialog && (
        <div ref={componentRef} className='shipments'>
          <ToastContainer
            style={{ width: 'auto' }}
            position='top-center'
            autoClose={6800}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/icon?family=Material+Icons'
          />
          <div
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className=''
          >
            <TextField
              size='small'
              sx={{
                width: '20vw',
                marginTop: '-49px',
                marginRight: '-5px'
              }}
              type='text'
              placeholder='Search...'
              value={searchText}
              onChange={e => handleSearchChange(e, '')}
              onKeyPress={handleSearchKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {searchText && (
                      <IconButton
                        onClick={e => handleSearchChange(e, 'clear')}
                        edge='end'
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />

            <Button
              variant='contained'
              sx={{
                marginTop: '-50px',
                marginRight: '100px',
                maxHeight: '40px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              onClick={() => {
                searchGlobal();
              }}
            >
              Search
            </Button>
          </div>
          <div>
            <MaterialReactTable
              enableRowNumbers
              rowNumberMode='original'
              muiTableHeadCellFilterTextFieldProps={() => ({
                placeholder: '' // Hide the placeholder,
              })}
              // muiTableHeadCellFilterContainerProps={{
              //   style: {
              //     display: 'flex',
              //     flexDirection: 'column-reverse'
              //   }
              // }}
              muiTableHeadProps={{
                style: {
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: '#fff'
                }
              }}
              // enableColumnFilters
              // enableColumnFilterModes
              // onPaginationChange={(pageState)=> console.log(pageState,'pageState')}
              onGlobalFilterChange={setGlobalFilter}
              // enableExpanding={
              //   (userPermissionsArr &&
              //     userPermissionsArr.filter(permissions => permissions.level2)
              //       .length) ||
              //   UserIsAdmin
              //     ? true
              //     : false
              // }
              // enableExpandAll={
              //   (userPermissionsArr &&
              //     userPermissionsArr.filter(permissions => permissions.level2)
              //       .length) ||
              //   UserIsAdmin
              //     ? true
              //     : false
              // }

              renderRowActionMenuItems={({ row }) => {
                const deliverCanceled = value === 4 || value === 5;
                const showView =
                  (value === 0 || value === 1) && row.original.quoteId !== 0;

                const showPickup =
                  (value === 0 || value === 1) &&
                  row.original.quoteId === 0 &&
                  (!checkLocLoader || row.index !== checkLocIndex);

                const showCancel =
                  (value === 0 || value === 1) &&
                  row.original.quoteId === 0 &&
                  (!checkLocLoader || row.index !== checkLocIndex);

                const showPickedUp = value === 2;

                const showHold =
                  value === 2 &&
                  (row.original.shipmentStatus.toLowerCase() === 'hold' ||
                    row.original.shipmentStatus.toLowerCase() !== 'hold');

                const showUnBook = value === 2;

                const showMarkAsDelivered =
                  value === 3 && row.original.shipmentType === 'TL';

                const showEdit =
                  (value === 2 || value === 3) &&
                  row.original.shipmentType !== 'TL';

                const bookShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level3)
                    .length ||
                    UserIsAdmin);

                const cancelShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level4)
                    .length ||
                    UserIsAdmin);
                const viewTenderShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level5)
                    .length ||
                    UserIsAdmin);
                const pickupShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level6)
                    .length ||
                    UserIsAdmin);
                const unBookShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level7)
                    .length ||
                    UserIsAdmin);
                const holdUnholdShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level8)
                    .length ||
                    UserIsAdmin);

                const updateShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level9)
                    .length ||
                    UserIsAdmin);
                const markAsDeliverShipAllowed =
                  userPermissionsArr &&
                  (userPermissionsArr.filter(permissions => permissions.level10)
                    .length ||
                    UserIsAdmin);

                return [
                  showView && viewTenderShipAllowed && (
                    <MenuItem
                      key='View'
                      onClick={() =>
                        handlePickupOpen(row.original, row, 'Get Quote')
                      }
                    >
                      <VisibilityIcon sx={{ mr: 1, color: 'primary.main' }} />
                      View
                    </MenuItem>
                  ),
                  showPickup && bookShipAllowed && (
                    <MenuItem
                      key='Pickup'
                      onClick={() =>
                        row.original.freightTerms.toLowerCase() === 'c' ||
                        row.original.freightTerms.toLowerCase() === 'n'
                          ? handlePickupOpen(row.original, row, 'Dispatch')
                          : handlePickupOpen(row.original, row, 'Get Quote')
                      }
                    >
                      <LaunchTwoToneIcon
                        sx={{ mr: 1, color: 'primary.main' }}
                      />
                      {row.original.freightTerms.toLowerCase() === 'c' ||
                      row.original.freightTerms.toLowerCase() === 'n'
                        ? 'Pickup'
                        : 'Book'}
                    </MenuItem>
                  ),
                  showCancel && cancelShipAllowed && (
                    <MenuItem
                      key='Cancel'
                      onClick={() => handleClickCancel(row)}
                    >
                      <DisabledByDefaultTwoToneIcon
                        sx={{ mr: 1, color: 'primary.main' }}
                      />
                      Cancel
                    </MenuItem>
                  ),
                  showPickedUp && pickupShipAllowed && (
                    <MenuItem
                      key='Picked Up'
                      onClick={() =>
                        handlePickupOpen(row.original, row, 'Dispatch')
                      }
                    >
                      <LaunchTwoToneIcon
                        sx={{ mr: 1, color: 'primary.main' }}
                      />
                      Picked Up
                    </MenuItem>
                  ),
                  showHold && holdUnholdShipAllowed && (
                    <MenuItem
                      key='Hold'
                      onClick={() => {
                        row.original.shipmentStatus.toLowerCase() === 'hold'
                          ? handleUnHoldShipmentRequest(row.original)
                          : handleClickOpen('Hold', row.original);
                      }}
                    >
                      <PanToolTwoToneIcon
                        sx={{ mr: 1, color: 'primary.main' }}
                      />
                      {row.original.shipmentStatus.toLowerCase() === 'hold'
                        ? 'UnHold'
                        : 'Hold'}
                    </MenuItem>
                  ),
                  showUnBook && unBookShipAllowed && (
                    <MenuItem
                      key='UnBook'
                      onClick={() => handleClickOpen('UnBook', row.original)}
                    >
                      <DisabledByDefaultTwoToneIcon
                        sx={{ mr: 1, color: 'primary.main' }}
                      />
                      UnBook
                    </MenuItem>
                  ),
                  showMarkAsDelivered && markAsDeliverShipAllowed && (
                    <MenuItem
                      key='Mark as Delivered'
                      onClick={() =>
                        // handleMarkDeliver(row.original, 'MarkDeliver')
                        handleMarkDeliver(row.original, 'MarkDeliver')
                      }
                    >
                      <TaskAltIcon sx={{ mr: 1, color: 'primary.main' }} />
                      Mark as Delivered
                    </MenuItem>
                  ),
                  showEdit && updateShipAllowed && (
                    <MenuItem
                      key='Edit'
                      onClick={() => handleClickOpen('EditRow', row.original)}
                    >
                      <EditIcon sx={{ mr: 1, color: 'primary.main' }} />
                      Edit
                    </MenuItem>
                  ),
                  deliverCanceled && <MenuItem key='View'>No Actions</MenuItem>
                ].filter(Boolean); // Remove null or false items
              }}
              // renderRowActions={({ row }) => {
              //   // If value is 2 or 3, render actions
              //   if (value === 2 || value === 3) {
              //     return (
              //       <>
              //         {/* Show Edit button for value 2 or 3, excluding 'TL' shipmentType */}
              //         {value === 2 ||
              //           (value === 3 && row.original.shipmentType !== 'TL' && (
              //             <Tooltip title='Edit' arrow>
              //               <Button
              //                 onClick={() => {
              //                   handleClickOpen('EditRow', row.original);
              //                 }}
              //               >
              //                 <EditIcon />
              //               </Button>
              //             </Tooltip>
              //           ))}

              //         {value === 2 ||
              //           (value === 3 && row.original.shipmentType === 'TL' && (
              //             <Tooltip title='Mark as Delivered' arrow>
              //               <Button
              //                 onClick={() => {
              //                   handleMarkDeliver(row.original, 'MarkDeliver');
              //                 }}
              //               >
              //                 <TaskAltIcon />
              //               </Button>
              //             </Tooltip>
              //           ))}

              //         {/* Show yellow overlay for value 3 and 'TL' shipmentType, if actualDeliveryDate is past */}
              //         {value === 3 &&
              //           row.original.shipmentType === 'TL' &&
              //           new Date(row.original.actualDeliveryDate) <
              //             new Date() && (
              //             <div
              //               style={{
              //                 backgroundColor: 'yellow',
              //                 width: '96%',
              //                 height: '60px',
              //                 position: 'absolute',
              //                 zIndex: 0,
              //                 margin: '-10px 0 0 0',
              //                 opacity: '0.2',
              //                 cursor: 'default'
              //               }}
              //             ></div>
              //           )}
              //       </>
              //     );
              //   }
              //   return null; // Return null when value is not 2 or 3
              // }}
              manualPagination
              // pageCount={200}
              rowCount={totalShipmentsCount}
              resetColumnOrder
              // autoResetAll
              autoResetExpanded={true}
              // onColumnFiltersChange={setColumnFilters}
              onColumnVisibilityChange={setColumnVisibility}
              onColumnOrderChange={setColumnOrder}
              state={{
                isLoading: shipmentsLoader,
                columnVisibility,
                globalFilter,
                columnOrder,
                pagination,
                // draggingColumn
                // columnFilters,
                // density,
                // globalFilter,
                showColumnFilters: true
                // showGlobalFilter,
                // sorting
              }}
              // rowCount={viewShipmentsData.length + 1}
              localization={{
                noRecordsToDisplay:
                  handleTotalRecords() === 0
                    ? 'No Records to Display'
                    : 'Loading ...'
              }}
              enablePagination={false}
              initialState={{
                // showColumnFilters: true,
                density: 'compact'
              }}
              columns={tableCol}
              muiTableBodyRowProps={{ row: { backgroundColor: 'red' } }}
              data={viewShipmentsData}
              enableColumnOrdering
              enableColumnActions
              enableGrouping={expandedDetail}
              // enableRowActions={value === 2 ? false : true}
              // renderRowActions={({ row, table }) =>
              //   userPermissionsArr &&
              //   (userPermissionsArr.filter(permissions => permissions.level4)
              //     .length ||
              //     UserIsAdmin) && (
              //     <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
              //       {(value === 0 || value === 1) &&
              //         row.original.quoteId !== 0 && (
              //           <Tooltip title='View' arrow>
              //             <Button
              //               onClick={() =>
              //                 handlePickupOpen(row.original, row, 'Get Quote')
              //               }
              //             >
              //               <VisibilityIcon />
              //             </Button>
              //           </Tooltip>
              //         )}
              //       {(value === 0 || value === 1) &&
              //         row.original.quoteId === 0 &&
              //         (checkLocLoader && row.index === checkLocIndex ? (
              //           <CircularProgress sx={{ ml: 2.5 }} size={23} />
              //         ) : (
              //           <Tooltip
              //             title={
              //               row.original.freightTerms.toLowerCase() === 'c' ||
              //               row.original.freightTerms.toLowerCase() === 'n'
              //                 ? 'Pickup'
              //                 : 'Book'
              //             }
              //             arrow
              //           >
              //             <Button
              //               onClick={() =>
              //                 row.original.freightTerms.toLowerCase() === 'c' ||
              //                 row.original.freightTerms.toLowerCase() === 'n'
              //                   ? handlePickupOpen(
              //                       row.original,
              //                       row,
              //                       'Dispatch'
              //                     )
              //                   : handlePickupOpen(
              //                       row.original,
              //                       row,
              //                       'Get Quote'
              //                     )
              //               }
              //             >
              //               {row.original.freightTerms.toLowerCase() === 'c' ||
              //               row.original.freightTerms.toLowerCase() === 'n' ? (
              //                 <LaunchTwoToneIcon />
              //               ) : (
              //                 <MonetizationOnTwoToneIcon />
              //               )}
              //             </Button>
              //           </Tooltip>
              //         ))}

              //       {(value === 0 || value === 1) &&
              //         row.original.quoteId === 0 &&
              //         (checkLocLoader && row.index === checkLocIndex ? (
              //           <CircularProgress sx={{ ml: 2.5 }} size={23} />
              //         ) : (
              //           <Tooltip title='Cancel' arrow>
              //             <Button onClick={() => handleClickCancel(row)}>
              //               <DisabledByDefaultTwoToneIcon />
              //             </Button>
              //           </Tooltip>
              //         ))}
              //       {/* {value === 0 && (
              //         <Tooltip title='Update Location' arrow>
              //           <Button
              //             onClick={() => handleOpenAddUpdateLocation(row)}
              //           >
              //             <EditLocationAltIcon />
              //           </Button>
              //         </Tooltip>
              //       )} */}
              //       {value === 2 && (
              //         <>
              //           <Tooltip title='Picked Up' arrow>
              //             <Button
              //               onClick={() => {
              //                 handlePickupOpen(row.original, row, 'Dispatch');
              //               }}
              //             >
              //               <LaunchTwoToneIcon />
              //             </Button>
              //           </Tooltip>
              //           <Tooltip
              //             title={
              //               row.original.shipmentStatus.toLowerCase() === 'hold'
              //                 ? 'UnHold'
              //                 : 'Hold'
              //             }
              //             arrow
              //           >
              //             <Button
              //               onClick={() => {
              //                 row.original.shipmentStatus.toLowerCase() ===
              //                 'hold'
              //                   ? handleUnHoldShipmentRequest(row.original)
              //                   : handleClickOpen('Hold', row.original);
              //               }}
              //             >
              //               {row.original.shipmentStatus.toLowerCase() ===
              //               'hold' ? (
              //                 <DoNotTouchIcon />
              //               ) : (
              //                 <PanToolTwoToneIcon />
              //               )}
              //             </Button>
              //           </Tooltip>
              //           <Tooltip title='UnBook' arrow>
              //             <Button
              //               onClick={() => {
              //                 handleClickOpen('UnBook', row.original);
              //               }}
              //             >
              //               <DisabledByDefaultTwoToneIcon />
              //             </Button>
              //           </Tooltip>
              //         </>
              //       )}
              //       {(value === 2 || value === 3) &&
              //         row.original.shipmentType !== 'TL' && (
              //           <Tooltip title='Edit' arrow>
              //             <Button
              //               onClick={() => {
              //                 handleClickOpen('EditRow', row.original);
              //               }}
              //             >
              //               <EditIcon />
              //             </Button>
              //           </Tooltip>
              //         )}
              //       {/* {value !== 0 &&
              //       value !== 1 &&
              //       value !== 5 &&
              //       row.original.shipmentType !== 'TL' && (
              //         <>
              //           <Tooltip title='Print Lables' arrow>
              //             <Button
              //               sx={{ zIndex: 1 }}
              //               onClick={() => printData(row.original)}
              //             >
              //               <PrintIcon />
              //             </Button>
              //           </Tooltip>
              //         </>
              //       )} */}
              //       {value === 3 &&
              //         row.original.shipmentType === 'TL' &&
              //         new Date(row.original.actualDeliveryDate) <
              //           new Date() && (
              //           <div
              //             style={{
              //               backgroundColor: 'yellow',
              //               width: '96%',
              //               height: '60',
              //               position: 'absolute',
              //               // left: 0,
              //               zIndex: 0,
              //               margin: '-10 0 0 0',
              //               opacity: '0.2',
              //               cursor: 'default'
              //             }}
              //           ></div>
              //         )}
              //       {value === 3 && row.original.shipmentType === 'TL' && (
              //         <Tooltip title='Mark as Delivered' arrow>
              //           <Button
              //             sx={{ zIndex: 1 }}
              //             onClick={() =>
              //               handleMarkDeliver(row.original, 'MarkDeliver')
              //             }
              //           >
              //             <TaskAltIcon />
              //           </Button>
              //         </Tooltip>
              //       )}
              //     </Box>
              //   )
              // }
              // // enableColumnResizing
              renderBottomToolbarCustomActions={() => (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }}
                >
                  {isLoading ? (
                    <Box sx={{ width: '20%' }}>
                      <LinearProgress variant='query' />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Select
                        sx={{ minWidth: 20, maxHeight: 30, mr: 1 }}
                        variant='outlined'
                        value={pagination.pageSize}
                        onChange={handleRecordsChange}
                        label='Records'
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                        <MenuItem value={80}>80</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        {/* <MenuItem value={handleTotalRecords()}>All</MenuItem> */}
                      </Select>
                      <Typography sx={{ marginRight: 2 }}>
                        Fetched <b>{viewShipmentsData.length}</b> out of{' '}
                        <b>
                          {searchQuery !== ''
                            ? totalShipmentsCount
                            : handleTotalRecords()}
                        </b>{' '}
                        total records.
                      </Typography>

                      {/* Records per page selector */}
                    </Box>
                  )}
                </div>
              )}
              // enableRowSelection={
              //   userPermissionsArr &&
              //   (userPermissionsArr.filter(permissions => permissions.level4)
              //     .length ||
              //     UserIsAdmin)
              //     ? true
              //     : false
              // }
              // muiTableBodyRowProps={({ row, isDetailPanel }) => ({
              //   backgroundColor: 'red'
              // })}
              // renderDetailPanel={({ row, prop }) =>
              //   handleViewShipments(row, prop)
              // }
              // muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
              // enableStickyHeader
              // enableStickyFooter
              // onExpandedChange={handleExpandedChange}
              renderDetailPanel={({ row }) =>
                userPermissionsArr &&
                (userPermissionsArr.filter(permissions => permissions.level2)
                  .length ||
                  UserIsAdmin)
                  ? handleViewShipments(row)
                  : null
              } // Conditionally render detail panel
              // onExpandedChange={handleExpandedChange} // Track expanded row
              muiTableContainerProps={{
                ref: tableContainerRef,
                sx: {
                  maxHeight: '70vh',
                  minHeight: '70vh',
                  // height: '500px',
                  overflow: 'auto'
                }
                // onScroll: (
                //   event //add an event listener to the table container element
                // ) => handleScroll(event.target)
              }}
              // muiTablePaginationProps={{
              //   rowsPerPageOptions: [
              //     5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150
              //   ],
              //   showFirstButton: true,
              //   showLastButton: false
              // }}ok
              // muiExpandButtonProps={({ row, table }) => ({
              //   onClick: () => handleFetchShipmentsDetails(row)
              // })}
              positionToolbarAlertBanner='bottom'
              renderToolbarInternalActions={({ table }) => (
                <div
                // style={
                //   isMobile
                //     ? { display: 'flex', width: '320px', overflowX: 'scroll' }
                //     : {
                //         display: 'flex',
                //         width: '100%'
                //       }
                // }
                >
                  {isMobile && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        bgcolor: 'background.paper'
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant='scrollable' // Makes the tabs scrollable
                        scrollButtons='auto' // Automatically shows scroll buttons when necessary
                        aria-label='scrollable tabs example'
                        allowScrollButtonsMobile
                        sx={{
                          ml: -2,
                          overflowX: 'auto', // Force horizontal scroll
                          whiteSpace: 'nowrap', // Prevent wrapping
                          '& .MuiTabs-scrollButtons': {
                            '&.Mui-disabled': { opacity: 0.3 }
                          }
                        }}
                      >
                        <Tab
                          sx={{
                            // minWidth: 'fit-content',
                            fontWeight: 600,
                            fontSize: 14,
                            width: '25%'
                          }}
                          label=<div className='Tabs_Badge_Title'>
                            <Badge
                              max={savedShipments}
                              color='primary'
                              sx={
                                savedShipments.toString().length > 3
                                  ? { padding: '0 15 0 0', marginLeft: 2 }
                                  : { padding: '0 20 0 0' }
                              }
                              badgeContent={savedShipments}
                            />
                            <div>Saved</div>
                          </div>
                          {...a11yProps(0)}
                        />
                        <Tab
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            width: '25%'
                          }}
                          label=<div className='Tabs_Badge_Title'>
                            <Badge
                              max={tenderedShipments}
                              color='error'
                              sx={
                                tenderedShipments.toString().length > 3
                                  ? { padding: '0 15 0 0', marginLeft: 2 }
                                  : { padding: '0 20 0 0' }
                              }
                              badgeContent={tenderedShipments}
                            />
                            <div>Tendered</div>
                          </div>
                          {...a11yProps(1)}
                        />
                        <Tab
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            width: '25%'
                          }}
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={bookedShipments}
                                sx={
                                  bookedShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={bookedShipments}
                                variant='standard'
                                color='secondary'
                              />
                            </div>
                            <div>Booked</div>
                          </div>
                          {...a11yProps(2)}
                        />
                        <Tab
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            width: '25%'
                          }}
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={inTransitShipments}
                                sx={
                                  inTransitShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={inTransitShipments}
                                variant='standard'
                                color='warning'
                              />
                            </div>
                            <div>In-Transit</div>
                          </div>
                          {...a11yProps(3)}
                        />
                        <Tab
                          sx={{
                            fontWeight: 600,
                            fontSize: 14,
                            width: '25%'
                          }}
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={deliveredShipments}
                                sx={
                                  deliveredShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={deliveredShipments}
                                variant='standard'
                                color='success'
                              />
                            </div>
                            <div>Delivered</div>
                          </div>
                          {...a11yProps(4)}
                        />
                      </Tabs>
                    </Box>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    {/* <MRT_ToggleGlobalFilterButton
                      table={table}
                      title='Search'
                    /> */}
                    <Tooltip arrow title='Print'>
                      <IconButton
                        sx={{ m: 0.5 }}
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        <LocalPrintshopOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip arrow title='Import'>
                      <IconButton
                        sx={{ m: 0.5 }}
                        onClick={() => fileInputRef?.current?.click()}
                      >
                        <input
                          ref={fileInputRef}
                          type='file'
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        ></input>
                        <svg
                          style={{ transform: 'rotate(180deg)' }}
                          width='17'
                          height='21'
                          viewBox='0 0 17 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.625 0.828125H2.625C1.525 0.828125 0.635 1.72812 0.635 2.82812L0.625 18.8281C0.625 19.9281 1.515 20.8281 2.615 20.8281H14.625C15.725 20.8281 16.625 19.9281 16.625 18.8281V6.82812L10.625 0.828125ZM14.625 18.8281H2.625V2.82812H9.625V7.82812H14.625V18.8281ZM4.625 13.8381L6.035 15.2481L7.625 13.6681V17.8281H9.625V13.6681L11.215 15.2581L12.625 13.8381L8.635 9.82812L4.625 13.8381Z'
                            fill='currentColor'
                          />
                        </svg>
                      </IconButton>
                    </Tooltip> */}
                    {/* Export funtion */}
                    {/* <Tooltip
                      sx={{ m: 0.5 }}
                      arrow
                      title={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                          ? 'Export All Rows'
                          : 'Export Selected Rows'
                      }
                    >
                      <IconButton
                        // onClick={handleExportData}
                        onClick={() =>
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                            ? handleExportData(table.getCoreRowModel().rows)
                            : handleExportRows(table.getSelectedRowModel().rows)
                        }
                        // sx={{ marginRight: -1.5 }}
                      >
                        <svg
                          width='17'
                          height='21'
                          viewBox='0 0 17 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.625 0.828125H2.625C1.525 0.828125 0.635 1.72812 0.635 2.82812L0.625 18.8281C0.625 19.9281 1.515 20.8281 2.615 20.8281H14.625C15.725 20.8281 16.625 19.9281 16.625 18.8281V6.82812L10.625 0.828125ZM14.625 18.8281H2.625V2.82812H9.625V7.82812H14.625V18.8281ZM4.625 13.8381L6.035 15.2481L7.625 13.6681V17.8281H9.625V13.6681L11.215 15.2581L12.625 13.8381L8.635 9.82812L4.625 13.8381Z'
                            fill='currentColor'
                          />
                        </svg>
                      </IconButton>
                    </Tooltip> */}

                    <MRT_ToggleDensePaddingButton
                      table={table}
                      title='Spacing'
                    />
                    <MRT_ShowHideColumnsButton
                      table={table}
                      title='Show/Hide Columns'
                    />
                    <MRT_FullScreenToggleButton
                      table={table}
                      title='Fullscreen'
                    />

                    <Tooltip title='Clear All Filters' arrow>
                      <IconButton
                        onClick={() => {
                          table.resetColumnFilters(true);
                          table.setShowColumnFilters(false);
                        }}
                      >
                        <FilterAltOffIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {/* <Tooltip title='Apply current grid settings' arrow>
                      <IconButton onClick={handleGridApplySettings}>
                        <GridOnIcon />
                      </IconButton>
                    </Tooltip> */}
                </div>
              )}
              renderTopToolbarCustomActions={({ table }) => {
                return (
                  !isMobile &&
                  !searchQuery && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        width: '100%',
                        bgcolor: 'background.paper'
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleTabChange}
                        variant='scrollable' // Makes the tabs scrollable
                        scrollButtons='auto' // Automatically shows scroll buttons when necessary
                        aria-label='scrollable tabs example'
                        allowScrollButtonsMobile
                        sx={
                          isMobile
                            ? {
                                overflowX: 'auto', // Force horizontal scroll
                                whiteSpace: 'nowrap', // Prevent wrapping
                                '& .MuiTabs-scrollButtons': {
                                  '&.Mui-disabled': { opacity: 0.3 }
                                }
                              }
                            : { mb: 1 }
                        }
                      >
                        <Tab
                          sx={
                            isMobile
                              ? {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: '25%'
                                }
                              : {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 150
                                }
                          }
                          label=<div className='Tabs_Badge_Title'>
                            <Badge
                              max={savedShipments}
                              color='primary'
                              sx={
                                savedShipments.toString().length > 3
                                  ? { padding: '0 15 0 0', marginLeft: 2 }
                                  : { padding: '0 20 0 0' }
                              }
                              badgeContent={savedShipments}
                            />
                            <div>Saved</div>
                          </div>
                          {...a11yProps(0)}
                        />
                        <Tab
                          sx={
                            isMobile
                              ? {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: '25%'
                                }
                              : {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 150
                                }
                          }
                          label=<div className='Tabs_Badge_Title'>
                            <Badge
                              max={tenderedShipments}
                              color='error'
                              sx={
                                tenderedShipments.toString().length > 3
                                  ? { padding: '0 15 0 0', marginLeft: 2 }
                                  : { padding: '0 20 0 0' }
                              }
                              badgeContent={tenderedShipments}
                            />
                            <div>Tendered</div>
                          </div>
                          {...a11yProps(1)}
                        />
                        <Tab
                          sx={
                            isMobile
                              ? {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: '25%'
                                }
                              : {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 150
                                }
                          }
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={bookedShipments}
                                sx={
                                  bookedShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={bookedShipments}
                                variant='standard'
                                color='secondary'
                              />
                            </div>
                            <div>Booked</div>
                          </div>
                          {...a11yProps(2)}
                        />
                        <Tab
                          sx={
                            isMobile
                              ? {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: '25%'
                                }
                              : {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 150
                                }
                          }
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={inTransitShipments}
                                sx={
                                  inTransitShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={inTransitShipments}
                                variant='standard'
                                color='warning'
                              />
                            </div>
                            <div>In-Transit</div>
                          </div>
                          {...a11yProps(3)}
                        />
                        <Tab
                          sx={
                            isMobile
                              ? {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: '25%'
                                }
                              : {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 150
                                }
                          }
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={deliveredShipments}
                                sx={
                                  deliveredShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={deliveredShipments}
                                variant='standard'
                                color='success'
                              />
                            </div>
                            <div>Delivered</div>
                          </div>
                          {...a11yProps(4)}
                        />

                        <Tab
                          sx={
                            isMobile
                              ? {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: '25%'
                                }
                              : {
                                  // minWidth: 'fit-content',
                                  fontWeight: 600,
                                  fontSize: 14,
                                  width: 150
                                }
                          }
                          label=<div className='Tabs_Badge_Title'>
                            <div style={{ backgroundColor: '#f3e5f5' }}>
                              <Badge
                                max={cancelledShipments}
                                sx={
                                  cancelledShipments.toString().length > 3
                                    ? { padding: '0 15 0 0', marginLeft: 2 }
                                    : { padding: '0 20 0 0' }
                                }
                                badgeContent={cancelledShipments}
                                variant='standard'
                                color='error'
                              />
                            </div>
                            <div>Cancelled</div>
                          </div>
                          {...a11yProps(5)}
                        />
                      </Tabs>
                    </Box>
                  )
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default memo(BasicTreeData);
