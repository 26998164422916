import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import './ImportCsv.css';
import { useState } from 'react';
import Papa from 'papaparse';
import { Col, Row } from 'reactstrap';
import { GetImportFieldNames, ImportCSVMapping } from '../../apis/Agency';
import { useSelector } from 'react-redux';
import { parse } from 'dotenv';
import AbcIcon from '@mui/icons-material/Abc';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import {
  MaterialReactTable,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function ImportCsv(props) {
  const fileInputRef = useRef(null);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const UserLoggedInEmail = useSelector(state => state.userDetails.email);

  const csvData = props?.location?.state?.csvData;
  const AllCsvData = props?.location?.state?.AllCsvData;
  const [CSVcolumnNames, setCsvColumnNames] = useState(null);
  const [onChangeRequiredTmsArray, setOnChangeRequiredTmsArray] = useState([]);
  // const [validationErrors, setValidationErrors] = useState({});
  const [importCsvBtn, setImportCsvBtn] = useState('Fetch Csv Data');
  const [csvApiError, setCsvApiError] = useState(false);
  const [csvApiErrorMsg, setCsvApiErrorMsg] = useState('');

  const [CsvApiReqArray, setCsvApiReqArray] = useState([
    {
      orderdate: '',
      pickupdate: '',
      pickupnumber: '',
      expecteddeliverydate: '',
      origincompany: '',
      originaddress: '',
      origincity: '',
      originzip: '',
      originstate: '',
      origincountry: '',
      destinationcompany: '',
      destinationaddress: '',
      destinationcity: '',
      destinationstate: '',
      destinationzip: '',
      destinationcountry: '',
      billtocompany: '',
      billtoaddress: '',
      billtocity: '',
      billtozip: '',
      billtocountry: '',
      billtostate: '',
      shipmenttype: '',
      equipmenttype: '',
      freightterms: '',
      customerid: '',
      salesordernumber: '',
      purchaseordernumber: '',
      freightinvoicenumber: '',
      itemid: '',
      itemtext: '',
      pallets: 0,
      requestedquantity: 0,
      netweight: 0,
      grossweight: 0,
      grosscharge: 0,
      netcharge: 0,
      discount: 0,
      ppaCharge: 0,
      packagingtype: '',
      nmfcclass: '',
      paymentterms: '',
      freightclass: '',
      bolnumber: '',
      shippingnotes: '',
      origincontactperson: '',
      origincontactemail: '',
      origincontactphone: '',
      destinationcontactperson: '',
      destinationcontactemail: '',
      destinationcontactphone: '',
      billtocontactperson: '',
      billtocontactemail: '',
      billtocontactphone: '',
      shipmentstatus: '',
      carriername: '',
      pronumber: '',
      location: ''
    }
  ]);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showTmsFieldIcon, setShowTmsFieldIcon] = useState(false);
  const [requiredTmsFieldNamesArr, setRequiredTmsFieldNamesArr] = useState([]);
  const [open, setOpen] = useState(false);
  const [CsvLoader, setCsvLoader] = useState(true);
  const [importCsvLoader, setImportCsvLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let arr = [];
    for (let i = 1; i < AllCsvData.length - 1; i++) {
      arr.push(...CsvApiReqArray);
    }
    setCsvApiReqArray(arr);
  }, []);

  const handleSuccessTmsImportFields = ApiRes => {
    setCsvLoader(false);
    let temp = [];
    let tempArr = [];

    for (let csvIndex = 0; csvIndex < csvData.length; csvIndex++) {
      const columnName = csvData[csvIndex].columnName;
      const firstValue = csvData[csvIndex].firstValue;

      const numberPattern = /^\d+$/;
      const IsNumber = numberPattern.test(firstValue);
      const isDateValue = !IsNumber && isValidDate(firstValue);

      const selectedInputs = ApiRes.find(
        items =>
          (((items.name === 'Pallets' ||
            items.name === 'RequestedQuantity' ||
            items.name === 'NetWeight' ||
            items.name === 'GrossWeight' ||
            items.name === 'GrossCharge' ||
            items.name === 'NetCharge' ||
            items.name === 'Discount' ||
            items.name === 'PPACharge') &&
            IsNumber) ||
            items.type === 'Date' ||
            items.type === 'String') &&
          items.name.toLowerCase() ===
            columnName.replace(/\s+/g, '').toLowerCase()
      )
        ? columnName.replace(/\s+/g, '')
        : '';

      const requiredInputs = ApiRes.find(
        items =>
          items.required &&
          items.name.toLowerCase() ===
            columnName.replace(/\s+/g, '').toLowerCase()
      )
        ? columnName.replace(/\s+/g, '')
        : '';

      temp.push({
        columnName: columnName,
        firstValue: firstValue,
        tmsSelectedFieldValue: selectedInputs,
        requiredTmSFields: requiredInputs,
        tmsFields: ApiRes,
        validationErrors: {}
      });

      let tempRequiredTmsArr = [];

      for (let index = 0; index < ApiRes.length; index++) {
        const required = ApiRes[index].required && ApiRes[index];

        if (required && !onChangeRequiredTmsArray.includes(required)) {
          tempRequiredTmsArr.push(...onChangeRequiredTmsArray, required);
          setOnChangeRequiredTmsArray(tempRequiredTmsArr);
        }
      }
      setCsvColumnNames(temp);
    }
  };

  useEffect(() => {
    GetImportFieldNames()
      .then(
        res => res.status === 200 && handleSuccessTmsImportFields(res.data.data)
      )
      .catch(err => {
        setCsvLoader(false);
      });
  }, []);

  function isValidDate(value) {
    var dateWrapper = new Date(value);
    return !isNaN(dateWrapper.getDate());
  }

  const convertFirstLetterToLower = inputString => {
    return inputString.replace(/^(.)/, match => match.toLowerCase());
  };

  const handleInputChange = (index, keyToUpdate, RowData) => {
    setCsvApiReqArray(prevArray => {
      const newArray = [...prevArray]; // Create a copy of the array
      newArray[index] = { ...newArray[index], [keyToUpdate]: RowData }; // Update the specified key

      return newArray;
    });
  };

  const handlePushRequiredFields = (newValue, validationsObj) => {
    let tempRequiredTmsArr = [];
    if (
      newValue &&
      validationsObj.TmsFieldError === null &&
      !onChangeRequiredTmsArray.includes(newValue)
    ) {
      tempRequiredTmsArr.push(...onChangeRequiredTmsArray, newValue);
      setOnChangeRequiredTmsArray(tempRequiredTmsArr);
    }
  };

  const handleTMScolumnChange = (
    columnName,
    firstValue,
    newValue,
    CsvIndex,
    item,
    event
  ) => {
    const RowName = newValue && newValue.name;

    const numberPattern = /^\d+$/;
    const IsNumber = numberPattern.test(firstValue);
    const isDateValue = !IsNumber && isValidDate(firstValue);
    // validations
    if (RowName) {
      // if (newValue.type === 'Date') {
      //   const newData = [...CSVcolumnNames];
      //   newData[CsvIndex].validationErrors['TmsFieldError'] =
      //     'Selected field type is Date ';
      //   newData[CsvIndex].tmsSelectedFieldValue = null;
      //   setCsvColumnNames(newData);
      // } else if (newValue.type === 'Date') {
      //   const newData = [...CSVcolumnNames];
      //   newData[CsvIndex].validationErrors['TmsFieldError'] = null;
      //   newData[CsvIndex].tmsSelectedFieldValue = RowName;
      //   setCsvColumnNames(newData);
      // } else
      if (
        (newValue.name === 'Pallets' ||
          newValue.name === 'RequestedQuantity' ||
          newValue.name === 'NetWeight' ||
          newValue.name === 'GrossWeight' ||
          newValue.name === 'GrossCharge' ||
          newValue.name === 'NetCharge' ||
          newValue.name === 'Discount' ||
          newValue.name === 'PPACharge') &&
        !IsNumber
      ) {
        const newData = [...CSVcolumnNames];
        newData[CsvIndex].validationErrors['TmsFieldError'] =
          'Selected field type is Integer';
        newData[CsvIndex].tmsSelectedFieldValue = null;
        setCsvColumnNames(newData);
      } else if (
        (newValue.name === 'Pallets' ||
          newValue.name === 'RequestedQuantity' ||
          newValue.name === 'NetWeight' ||
          newValue.name === 'GrossWeight' ||
          newValue.name === 'GrossCharge' ||
          newValue.name === 'NetCharge' ||
          newValue.name === 'Discount' ||
          newValue.name === 'PPACharge') &&
        IsNumber
      ) {
        const newData = [...CSVcolumnNames];
        newData[CsvIndex].validationErrors['TmsFieldError'] = null;
        newData[CsvIndex].tmsSelectedFieldValue = RowName;
        setCsvColumnNames(newData);
      } else {
        const newData = [...CSVcolumnNames];
        newData[CsvIndex].validationErrors['TmsFieldError'] = null;
        newData[CsvIndex].tmsSelectedFieldValue = RowName;
        setCsvColumnNames(newData);
      }
    }
    handlePushRequiredFields(newValue, item.validationErrors);

    // Api array req handling
    if (
      CsvApiReqArray.find(obj =>
        Object?.keys(obj)?.some(
          value => value?.toLowerCase() === RowName?.toLowerCase()
        )
      ) &&
      item.validationErrors.TmsFieldError === null
    ) {
      let keyoUpdate = RowName.toLowerCase().replace(/\s+/g, '');

      for (let index = 1; index < AllCsvData.length - 1; index++) {
        const RowData = AllCsvData[index][CsvIndex];
        handleInputChange(index - 1, keyoUpdate, RowData);
      }
    }
  };

  const handleImportCsvMapping = () => {
    const TmsFieldsArr = CSVcolumnNames[0].tmsFields;
    const EmptyTmsFields = CSVcolumnNames.filter(
      items => items.tmsSelectedFieldValue !== ''
    );
    const RequiredEmptyTmsFields = onChangeRequiredTmsArray.filter(
      items =>
        !EmptyTmsFields.some(miss => miss.tmsSelectedFieldValue === items.name)
    );

    let tempRequiredTmsFieldNamesArr = [];

    for (let index = 0; index < TmsFieldsArr.length; index++) {
      const RequiredStatus = TmsFieldsArr[index].required;
      const RequiredTmsField = TmsFieldsArr[index].name;
      if (RequiredStatus) {
        !onChangeRequiredTmsArray.find(
          item => item.name === RequiredTmsField
        ) && tempRequiredTmsFieldNamesArr.push(RequiredTmsField);
      }
    }

    if (RequiredEmptyTmsFields.length > 0) {
      for (let index = 0; index < RequiredEmptyTmsFields.length; index++) {
        const missingTmsFields = RequiredEmptyTmsFields[index].name;

        tempRequiredTmsFieldNamesArr.push(missingTmsFields);
      }
    }
    setRequiredTmsFieldNamesArr(tempRequiredTmsFieldNamesArr);
    if (tempRequiredTmsFieldNamesArr.length > 0) {
      setOpen(true);
    } else {
      if (importCsvBtn === 'Fetch Csv Data') {
        setImportCsvLoader(true);
        setImportCsvBtn('Import');
      }
      // ImportCsvServerRequest();
      setOpen(false);
      for (let i = 0; i < CSVcolumnNames.length; i++) {
        const selectedInputs = CSVcolumnNames[i].tmsSelectedFieldValue;
        let keyoUpdate = selectedInputs.replace(/\s+/g, '').toLowerCase();
        for (let j = 1; j < AllCsvData.length - 1; j++) {
          const RowData = AllCsvData[j][i];
          handleInputChange(j - 1, keyoUpdate, RowData);
        }
      }
      if (importCsvBtn === 'Import') {
        setCsvLoader(true);
        handleImportCsvRequest();
        // setImportCsvLoader(false);
      }
    }
  };

  const handleImportCsvRequest = () => {
    setCsvLoader(true);
    ImportCSVMapping(CsvApiReqArray, AgencyID, UserLoggedInEmail)
      .then(res => {
        res.status === 200 && setCsvLoader(false);
        successToast('Csv updated Successfully!');
        setTimeout(() => {
          history.push('/manage-shipments');
        }, 2500);
      })
      .catch(err => {
        setCsvLoader(false);
        setCsvApiError(true);
        setCsvApiErrorMsg(JSON.stringify(err?.response?.data?.errors));
      });
  };
  // Csv Table Preview
  const mncolumns = [
    {
      accessorKey: 'orderdate',
      id: 'OrderDate',
      header: 'Order Date',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'pickupdate',
      id: 'PickupDate',
      header: 'Pickup Date',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'expecteddeliverydate',
      id: 'expecteddeliverydate',
      header: 'Delivery Date',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'originaddress',
      id: 'OriginAddress',
      header: 'Origin Address',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'origincity',
      id: 'OriginCity',
      header: 'Origin City',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'origincompany',
      id: 'origincompany',
      header: 'Origin Company',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'origincontactemail',
      id: 'origincontactemail',
      header: 'Origin Contact Email',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'origincontactperson',
      id: 'freigorigincontactpersonhtterms',
      header: 'Origin Contact Person',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'origincontactphone',
      id: 'origincontactphone',
      header: 'Origin Contact Phone',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'origincountry',
      id: 'origincountry',
      header: 'Origin Country',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'originstate',
      id: 'originstate',
      header: 'Origin State',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'originzip',
      id: 'originzip',
      header: 'Origin Zip',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'destinationaddress',
      id: 'destinationaddress',
      header: 'Destination Address',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'destinationcity',
      id: 'destinationcity',
      header: 'Destination City',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'destinationstate',
      id: 'destinationstate',
      header: 'Destination State',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'destinationzip',
      id: 'destinationzip',
      header: 'Destination Zip',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'destinationcontactemail',
      id: 'destinationcontactemail',
      header: 'Destination Contact Email',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'destinationcontactperson',
      id: 'destinationcontactperson',
      header: 'Destination Contact Person',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'destinationcontactphone',
      id: 'destinationcontactphone',
      header: 'Destination Contact Phone',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtoaddress',
      id: 'billtoaddress',
      header: 'Bill to Address',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },

    {
      accessorKey: 'billtocity',
      id: 'billtocity',
      header: 'Bill to City',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtostate',
      id: 'billtostate',
      header: 'Bill to State',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtozip',
      id: 'billtozip',
      header: 'Bill to Zip',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtocompany',
      id: 'billtocompany',
      header: 'Bill to Company',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtocontactemail',
      id: 'billtocontactemail',
      header: 'Bill to Contact Email',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtocontactperson',
      id: 'billtocontactperson',
      header: 'Bill to Contact Person',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtocontactphone',
      id: 'billtocontactphone',
      header: 'Bill to Contact Phone',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'billtocountry',
      id: 'billtocountry',
      header: 'Bill to Country',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },

    {
      accessorKey: 'packagingtype',
      id: 'packagingtype',
      header: 'Packaging Type',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'pallets',
      id: 'pallets',
      header: 'Pallets',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'paymentterms',
      id: 'paymentterms',
      header: 'Payment terms',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'freightterms',
      id: 'freightterms',
      header: 'Freight terms',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'pickupnumber',
      id: 'pickupnumber',
      header: 'Pickup Number',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'ppaCharge',
      id: 'ppaCharge',
      header: 'PPA Charge',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'pronumber',
      id: 'pronumber',
      header: 'Pro Number',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'purchaseordernumber',
      id: 'purchaseordernumber',
      header: 'Purchase Order Number',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'requestedquantity',
      id: 'requestedquantity',
      header: 'Requested Quantity',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'salesordernumber',
      id: 'salesordernumber',
      header: 'Sales Order Number',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'shipmentstatus',
      id: 'shipmentstatus',
      header: 'Shipment Status',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'shipmentType',
      id: 'shipmentType',
      header: 'Shipment Mode',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'shippingnotes',
      id: 'shippingnotes',
      header: 'Shipping Notes',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'bolnumber',
      id: 'bolnumber',
      header: 'Bol Number',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'carriername',
      id: 'carriername',
      header: 'Carrier Name',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'customerid',
      id: 'customerid',
      header: 'Customer Id',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'discount',
      id: 'discount',
      header: 'Discount',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'equipmenttype',
      id: 'equipmenttype',
      header: 'Equipment Type',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'freightclass',
      id: 'freightclass',
      header: 'Freight Class',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'freightinvoicenumber',
      id: 'freightinvoicenumber',
      header: 'Freight Invoice Number',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'grosscharge',
      id: 'grosscharge',
      header: 'Gross Charge',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'grossweight',
      id: 'grossweight',
      header: 'Gross Weight',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'itemid',
      id: 'itemid',
      header: 'Item Id',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'itemtext',
      id: 'itemtext',
      header: 'Item Text',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'location',
      id: 'location',
      header: 'Location',
      size: 70,
      Cell: ({ cell, row }) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={
                cell.getValue() === ''
                  ? { color: 'red', fontWeight: 'bold' }
                  : {}
              }
              class='mx-1'
            >
              {cell.getValue() === '' ? 'Required field' : cell.getValue()}
            </span>
          </Box>
        );
      }
    },
    {
      accessorKey: 'netcharge',
      id: 'netcharge',
      header: 'Net Charge',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'netweight',
      id: 'netweight',
      header: 'Net Weight',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'nmfcclass',
      id: 'nmfcclass',
      header: 'NMFC Class',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    }
  ];

  return (
    <Paper sx={{ marginLeft: 2, marginRight: 2 }}>
      <Box sx={{ backgroundColor: 'white', overflow: 'hidden' }}>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          style={{ width: 'auto' }}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div style={{ display: 'flex', padding: 10 }}>
          {importCsvBtn === 'Import' && (
            <ArrowBackIcon
              onClick={() => {
                setImportCsvLoader(false);
                setImportCsvBtn('Fetch Csv Data');
              }}
              sx={{ m: 1, cursor: 'pointer' }}
            />
          )}
          <Button
            sx={{ textTransform: 'capitalize' }}
            variant='contained'
            onClick={() => handleImportCsvMapping()}
          >
            {importCsvBtn}
          </Button>
          <input
            ref={fileInputRef}
            type='file'
            //   onChange={handleFileChange}
            style={{ display: 'none' }}
          ></input>
        </div>
        <Collapse in={csvApiError}>
          <Alert severity='error' onClose={() => setCsvApiError(false)}>
            <AlertTitle>
              Please Enter Data in Highlighted Required CSV Fields!
            </AlertTitle>
            {/* {csvApiErrorMsg} */}
          </Alert>
        </Collapse>
        <Collapse in={open}>
          <Alert severity='error' onClose={() => setOpen(false)}>
            <AlertTitle>Select Required TMS Fields</AlertTitle>
            {requiredTmsFieldNamesArr.map((item, index) => `${item}, `)}
          </Alert>
        </Collapse>

        <hr />
        {importCsvLoader ? (
          <MaterialReactTable
            columns={mncolumns}
            data={CsvApiReqArray}
            enableRowSelection
            muiTableBodyRowProps={({ row }) => ({
              onClick: row.getToggleSelectedHandler()

              // sx: { cursor: 'pointer' },
            })}
            renderTopToolbarCustomActions={({ table }) => {
              return (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <Typography variant='h5'>Csv Preview</Typography>
                </div>
              );
            }}
            // enableMultiSort
            localization={{
              rowNumbers: '15'
            }}
            muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
            // enableStickyHeader
            // enableStickyFooter

            muiTableContainerProps={{
              sx: { maxHeight: '70vh', minHeight: '70vh' }
            }}
            initialState={{
              // showColumnFilters: true,
              density: 'compact'
            }}
            state={{ isLoading: CsvLoader }}
            positionToolbarAlertBanner='bottom'
            renderToolbarInternalActions={({ table }) => (
              <>
                <MRT_ToggleGlobalFilterButton table={table} title='Search' />

                <MRT_ToggleFiltersButton table={table} title='Filters' />

                <MRT_ToggleDensePaddingButton table={table} title='Spacing' />
                <MRT_ShowHideColumnsButton
                  table={table}
                  title='Show/Hide Columns'
                />
                <MRT_FullScreenToggleButton table={table} title='Fullscreen' />
              </>
            )}
          />
        ) : (
          <>
            <div className='columns-header'>
              <h5 style={{ width: 350 }}>Csv File Columns:</h5>
              <h5 style={{ width: 350 }}>TMS Columns:</h5>
              {/* <h5 style={{ width: 350 }}>Comments:</h5> */}
            </div>
            <hr />
            <Box sx={{ overflowX: 'hidden', height: '70vh' }}>
              <div>
                <ol>
                  {CsvLoader ? (
                    <div className='table-row-loader'>
                      <CircularProgress />
                    </div>
                  ) : CSVcolumnNames ? (
                    CSVcolumnNames.map((item, index) => (
                      <>
                        <div className='table-row'>
                          <div style={{ width: 330 }}>
                            <li key={index} style={{}}>
                              <strong>
                                {item.columnName}
                                <br />{' '}
                              </strong>{' '}
                              {item.firstValue}
                            </li>
                          </div>
                          <div style={{ marginTop: 5 }}>
                            <Autocomplete
                              filterOptions={(options, state) =>
                                options.filter(option =>
                                  option.name
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                )
                              }
                              size='small'
                              value={item.tmsSelectedFieldValue}
                              onChange={(event, newValue) => {
                                handleTMScolumnChange(
                                  item.columnName,
                                  item.firstValue,
                                  newValue,
                                  index,
                                  item,
                                  event
                                );
                              }}
                              renderOption={(props, option) => (
                                <Box style={{ fontSize: 13 }} {...props}>
                                  {option.name}
                                </Box>
                              )}
                              id='controllable-states-demo'
                              options={item.tmsFields}
                              getOptionLabel={option => {
                                if (item.tmsSelectedFieldValue === option) {
                                  return option;
                                } else {
                                  return item.tmsSelectedFieldValue;
                                }
                              }}
                              sx={{ width: 350 }}
                              renderInput={params => (
                                <TextField
                                  error={Boolean(
                                    item.validationErrors['TmsFieldError']
                                  )}
                                  helperText={
                                    item.validationErrors['TmsFieldError']
                                  }
                                  {...params}
                                  required={item.requiredTmSFields}
                                  variant='outlined'
                                  label='To import, select a field'
                                />
                              )}
                            />
                          </div>
                          <Chip
                            sx={{ m: 1, width: 150 }}
                            label={
                              item.tmsSelectedFieldValue
                                ? 'Matched'
                                : 'Not Matched'
                            }
                            color={
                              item.tmsSelectedFieldValue ? 'success' : 'error'
                            }
                          />
                        </div>
                        <hr />
                      </>
                    ))
                  ) : (
                    <div className='table-row'>
                      <div className='Csv-Error'>
                        <Typography variant='h4'>Network Error !</Typography>
                      </div>
                    </div>
                  )}
                </ol>
              </div>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
}
