import {
  Avatar,
  AvatarGroup,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { FailedLogs } from '../../apis/Agency';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PreviewIcon from '@mui/icons-material/Preview';
import { Cancel, ContentCopy } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector } from 'react-redux';
import { base_logo_url } from '../../apis/constants';
import NoLogo from '../../assets/carrier logos/No Logo.png';

export default function ViewLogos() {
  const [logsArray, setLogsArray] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [value, setValue] = React.useState(0);
  const [logsLoader, setLogsLoader] = useState(false);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );

  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);

  const mtransitTabColumns = useMemo(
    () => [
      {
        accessorKey: 'createdBy',
        id: 'createdBy',
        header: 'User',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <span>{renderedCellValue}</span> {/* Display the username */}
              <span style={{ fontSize: '12px', color: 'gray' }}>
                {row.original.modifiedBy}
              </span>{' '}
            </Box>
          </>
        )
      },
      // {
      //   accessorKey: 'modifiedBy',
      //   id: 'modifiedBy',
      //   header: 'Email',
      //   size: 50,
      //   filterFn: (row, colID, filterValue) => {
      //     return row.original.proNumber
      //       .toLowerCase()
      //       .includes(filterValue.toLowerCase());
      //   },
      //   Cell: ({ renderedCellValue, row }) => (
      //     <>
      //       <Box sx={{ display: 'flex', alignItems: 'center' }}>
      //         <span>{renderedCellValue}</span>
      //         <br />
      //       </Box>
      //     </>
      //   )
      // },
      {
        accessorKey: 'shipmentType',
        id: 'shipmentType',
        header: 'Shipment Mode',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'carrier',
        id: 'carrier',
        header: 'Carrier',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            {renderedCellValue && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AvatarGroup sx={{}} spacing='small'>
                  <Avatar
                    sx={
                      {
                        // bgcolor: 'ButtonFace',
                        // objectFit: 'contain',
                        // mr: 1
                      }
                    }
                    imgProps={{
                      style: {
                        // height: 25
                      }
                    }}
                    // src={base_logo_url + carrier_logos/${items.logo}`}
                    src={
                      row.original.carrierLogo
                        ? base_logo_url + row.original.carrierLogo
                        : NoLogo
                    }
                  />
                </AvatarGroup>
                <span>{renderedCellValue}</span>
                <br />
              </Box>
            )}
          </>
        )
      },
      {
        accessorKey: 'source',
        id: 'source',
        header: 'Request Source',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'logTime',
        id: 'logTime',
        header: 'Date',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'action',
        id: 'action',
        header: 'Action',
        size: 100,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'message',
        id: 'message',
        header: 'Error Message',
        size: 350,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row, column }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 300
              }}
            >
              <span style={{ width: 270 }}>
                {renderedCellValue?.length > 40
                  ? `${renderedCellValue?.substring(0, 40)}...`
                  : renderedCellValue}
              </span>
              {renderedCellValue?.length > 40 && (
                <VisibilityIcon
                  sx={{ cursor: 'pointer', marginLeft: 1 }}
                  aria-describedby={id}
                  variant='contained'
                  onClick={e => {
                    setDialogContent(renderedCellValue);
                    setDialogTitle(column.id);
                    handleClick(e);
                  }}
                />
              )}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'messageLog',
        id: 'messageLog',
        header: 'Details',
        size: 350,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row, column }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 300
              }}
            >
              <span>
                {renderedCellValue.length > 40
                  ? `${renderedCellValue.substring(0, 40)}...`
                  : renderedCellValue}
              </span>
              {renderedCellValue.length > 40 && (
                <VisibilityIcon
                  sx={{ cursor: 'pointer', marginLeft: 1 }}
                  aria-describedby={id}
                  variant='contained'
                  onClick={e => {
                    setDialogContent(renderedCellValue);
                    setDialogTitle(column.id);
                    handleClick(e);
                  }}
                />
              )}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'messageRequest',
        id: 'messageRequest',
        header: 'Request',
        size: 350,
        enableClickToCopy: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' }
        },
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row, column }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 300
              }}
            >
              <span>
                {renderedCellValue.length > 40
                  ? `${renderedCellValue.substring(0, 40)}...`
                  : renderedCellValue}
              </span>
              {renderedCellValue.length > 40 && (
                <VisibilityIcon
                  sx={{ cursor: 'pointer', marginLeft: 1 }}
                  aria-describedby={id}
                  variant='contained'
                  onClick={e => {
                    setDialogContent(renderedCellValue);
                    setDialogTitle(column.id);
                    handleClick(e);
                  }}
                />
              )}
            </Box>
          </>
        )
      }
    ],
    []
  );

  const [tableColumns, setTableColumns] = useState(mtransitTabColumns);

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Logs'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);

  useEffect(() => {
    if (userPermissionsArr) {
      let filteredColumns = [...mtransitTabColumns];

      if (
        !userPermissionsArr.filter(permissions => permissions.level2).length &&
        !UserIsAdmin
      ) {
        filteredColumns = filteredColumns.filter(
          item => item.accessorKey !== 'messageLog'
        );
      }

      if (
        !userPermissionsArr.filter(permissions => permissions.level3).length &&
        !UserIsAdmin
      ) {
        filteredColumns = filteredColumns.filter(
          item => item.accessorKey !== 'messageRequest'
        );
      }

      setTableColumns(filteredColumns);
    }
  }, [userPermissionsArr]);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setLogsLoader(true);
    FailedLogs()
      .then(res => {
        console.log(res.data, 'res.data.data');
        res.status === 200 && setLogsLoader(false);
        setLogsArray(res.data.data);
      })
      .catch(err => console.log(err, 'Failed Error'));
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        mt: 1
      }}
    >
      <MaterialReactTable
        renderTopToolbarCustomActions={() => {
          return (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                >
                  <Tab label='Shipments Logs' {...a11yProps(0)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Dialog
                  PaperProps={{
                    style: {
                      minWidth: '30vw',
                      height: '50vh'
                    }
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                      // p: 1
                    }}
                    id='alert-dialog-title'
                  >
                    <Box>{dialogTitle}</Box>
                    <Box>
                      <Cancel
                        onClick={handleClose}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Box>
                  </DialogTitle>

                  <DialogContent sx={{ m: 2 }}>
                    <Box>
                      <Typography>{dialogContent}</Typography>
                    </Box>
                  </DialogContent>
                </Dialog>
              </CustomTabPanel>
            </>
          );
        }}
        // enableRowActions={false}
        // enableRowSelection={false}
        columns={tableColumns}
        data={logsArray}
        // enableTopToolbar={false}
        // enableExpanding
        // autoResetExpanded={true}
        state={{
          isLoading: logsLoader
        }}
        initialState={{
          density: 'compact',
          isLoading: logsLoader
        }}
        //   renderDetailPanel={({ row }) => handleViewShipments(row)}
        muiTableContainerProps={{
          // ref: tableContainerRef,
          sx: {
            maxHeight: '70vh',
            minHeight: '70vh',
            maxWidth: '92vw',
            minWidth: '92vw'
            // height: '500px',
            // overflowX: 'scroll'
          }
        }}
        // enablePagination={false}
      />
    </Box>
  );
}
