import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePciker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { deformatDateUTC, formatDateUTC } from '../../apis/Utils';

export const DateRangeFilter = ({
  styleProp,
  selected,
  onChange,
  placeholderText,
  markingDone = false,
  setOpenDeliverDialog
}) => {
  // Handle date change with UTC formatting
  const handleDateChange = date => {
    const finalDate = formatDateUTC(date);
    console.log(finalDate, date, 'date format'); // Logs the UTC formatted date
    onChange({ date: date, displayDate: finalDate }); // Pass the original date to parent
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .custom-calendar {
        background-color: #f0f8ff !important; /* Light blue background */
        border-radius: 10px !important;
        font-size: 13px;
        padding: 1px;
      }
      .custom-popper {
        z-index: 1050 !important; /* Ensures it's on top */
      }
      .custome_date_picker {
        position: relative;
        display: inline-block;
        width: 100%;
      }
      .custom-input {
        width: 100%;
        padding-left: 30px; /* Space for the calendar icon */
        padding-right: 10px; /* Space for the right padding */
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 8px 10px;
        font-size: 13px;
        box-sizing: border-box; /* Make sure padding is included in width */
        position: relative;
      }
   .custome_date_picker .custom-input {
  width: 100%;
  padding-right: 30px; /* Space for the icon */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 10px;
  cursor: pointer; /* Pointer cursor on input hover */
}

/* Position the calendar icon inside the input */
.custome_date_picker .custome {
  position: absolute;
  right: 1vw; /* Adjust distance from right */
  top: 50%;
  transform: translateY(-50%);
  color: #333; /* Dark color for the icon */
  cursor: pointer; /* Pointer cursor on icon hover */
  z-index: ; /* Ensure the icon is above the input */
  pointer-events: auto; /* Allow interaction with the icon */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.shipments_date_picker .deliver_custome {
  position: absolute;
  right: 190px; /* Adjust distance from right */
 top: 77px;
  transform: translateY(-50%);
  color: #333; /* Dark color for the icon */
  cursor: pointer; /* Pointer cursor on icon hover */
  z-index: ; /* Ensure the icon is above the input */
  pointer-events: auto; /* Allow interaction with the icon */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

/* On hover over the icon, change color */
.custome_date_picker .custome:hover {
  color: #1976d2; /* Change icon color on hover */
}

.shipments_date_picker .deliver_custome:hover {
 color: #1976d2;
}

/* Fix z-index to ensure icon doesn't block input */
.custome_date_picker .react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 0; /* Ensure the input is below the icon */
}
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const datepickerRef = useRef(null);
  const handleIconClick = () => {
    if (datepickerRef.current) {
      datepickerRef.current.setFocus();
    }
  };
  return (
    <div
      className={
        styleProp === 'shipmentsTable'
          ? 'shipments_date_picker'
          : 'custome_date_picker'
      }
    >
      <DatePicker
        ref={datepickerRef}
        selected={selected}
        onChange={handleDateChange}
        dateFormat='MMM d, yyyy'
        placeholderText={placeholderText}
        calendarClassName='custom-calendar'
        popperClassName='custom-popper'
        className='custom-input'
        popperPlacement='bottom-start'
        onCalendarOpen={() => {
          if (markingDone) {
            setOpenDeliverDialog(true);
          }
        }}
        onCalendarClose={() => {
          if (markingDone) {
            setOpenDeliverDialog(false);
          }
        }}
      />
      <CalendarMonthIcon
        className={
          styleProp === 'shipmentsTable' ? 'deliver_custome' : 'custome'
        }
        onClick={handleIconClick}
      />
    </div>
  );
};
