import React from 'react';
import Routes from './Routes/index';

//import Custom Style scss
import './assets/scss/themes.scss';

function SignalRApp() {
    return (
        <div
        // style={
        //   layoutMode === 'dark'
        //     ? { backgroundColor: '#303841' }
        //     : { backgroundColor: '#EDF2F9' }
        // }
        >
            <Routes />
        </div>
    );
}

export default SignalRApp;
