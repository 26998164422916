







import { Box } from "@material-ui/core";
import { Button, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import { GetGeneralSettings, UpdateGeneralSettings } from "../../apis/Agency";


import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';


import './CreateUser.css';


function GeneralSettings() {

    const isMobile = useIsMobile();

    const [settings, setSettings] = useState([]);


    const fetchSettings = () => {

        GetGeneralSettings().then(res => {
            setSettings(res?.data?.data?.sort((a, b) => a.sOrder - b.sOrder));

        }).catch(err => {
            console.log(err, 'SettingsError')
        })

    }

    useEffect(() => {
        fetchSettings();
    }, [])


    const handleUpdateSettings = () => {
        const finalData = settings.map((sett) => ({
            key: sett.sName,
            value: sett.sValue
        }))
        console.log(finalData, 'SettingsError')
        UpdateGeneralSettings(finalData).then(res => {
            successToast('Settings saved successfully.')
            fetchSettings();
        }).catch(err => {
            ErrorToast(err?.message || 'Settings save failed')
            console.log(err, 'SettingsErrorUpdate')
        })
    }


    return (
        <Box className="p-3" sx={{ maxHeight: '75vh', minHeight: '75vh', overflow: 'auto' }}>
            <ToastContainer
                position='top-center'
                style={
                    isMobile
                        ? { width: '100%', display: 'flex', justifyContent: 'center' }
                        : { width: 'auto' }
                }
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Box className="d-flex justify-content-end">
                <Button variant="contained" style={{ marginTop: -67, zIndex: 99, position: 'absolute' }} onClick={handleUpdateSettings}>
                    Save Settings
                </Button>
            </Box>



            <Box className="d-flex flex-column align-items-center gap-3">
                {settings.map((setting, index) => (
                    <div key={index} className="bg-white px-3 py-2 w-100 d-flex justify-content-between align-items-center" style={{ borderRadius: 6 }}>
                        <Typography>{setting.sNameCaption}</Typography>
                        {setting.sValueDataType === 'BOOLEAN' &&
                            <Switch checked={setting.sValue === "true"} onChange={(e) => setSettings(prevSettings => prevSettings.map((sett, i) => i===index ? {...sett, sValue: e.target.checked ? 'true' : 'false' } : sett))} />
                        }

                    </div>
                ))}
            </Box>



        </Box>
    )
}

export default GeneralSettings;