// import { Skeleton } from '@mui/material';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => {
  const rows = 10;
  const columns = 10;
  return (
    <>
      <div
        style={{
          padding: '10px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#f8f8f8'
        }}
      >
        <Skeleton height={40} width={100} />
        <Skeleton circle height={50} width={50} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            flex: '0 0 7%',
            padding: '20px',
            width: '10px',
            height: '100vh',
            backgroundColor: '#f0f0f0'
          }}
        >
          <Skeleton height={40} />
          <Skeleton height={20} count={7} style={{ marginTop: '20px' }} />
        </div>
        <div style={{ padding: '20px', flex: '0 0 90%' }}>
          <Skeleton height={30} width={100} style={{ marginBottom: '20px' }} />
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {[...Array(columns)].map((_, index) => (
                  <th
                    key={index}
                    style={{ padding: '10px', textAlign: 'left' }}
                  >
                    <Skeleton height={20} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[...Array(rows)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {[...Array(columns)].map((_, colIndex) => (
                    <td key={colIndex} style={{ padding: '10px' }}>
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SkeletonLoader;
