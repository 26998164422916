import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './navbar.css';
import { AagencyLogout } from '../../apis/Agency';
import { setRateValues } from '../../Redux/GetRatesSlice';
import {
  setEmailRedux,
  setLoginAgencyID,
  setPasswordRedux,
  setUserDetailsObj,
  setUserRolePermissionsArr,
  setUserToken
} from '../../Redux/UserSlice';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import {
  setQuickQuoteDeliveryCheckedRedux,
  setQuickQuotePickUpCheckedRedux
} from '../../Redux/QuickQuoteSwevicesSlice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import {
  setClassWeight,
  setDimensionsWeight
} from '../../Redux/QuickQuoteTotalWeightSlice';
import './additionalSideBar.css';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { base_logo_url } from '../../apis/constants';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import MenuIcon from '@mui/icons-material/Menu';

import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import NotificationCard from '../../pages/Notifications/NotificationCard';
import { formatNotificationTime, sanitizeHTML } from '../../apis/Utils';
import NotificationDialog from '../../pages/Notifications/NotificationDialog';

const NavBar = props => {
  const UserDetails = useSelector(state => state.user.UserDetailsObj);
  const AgencyAdminDetails = useSelector(
    state => state.user.AgencyAdminDetails
  );
  const UserNotificationsObj = useSelector(
    state => state.user.UserAppNotifications
  );

  const UserNotifications = UserNotificationsObj?.notifications || [];

  const [shipmentColor, setShipmentColor] = useState(false);
  const [quickColor, setQuickColor] = useState(false);

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile(prevState => !prevState);

  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const dropDownNotification = () =>
    setNotificationDropDown(prevState => !prevState);

  //scroll navbar
  const [navigate, setNavigate] = useState('');

  setTimeout(() => {
    const Home = localStorage.getItem('Home');
    setNavigate(Home);
  }, 3000);

  const dispatch = useDispatch();
  const rememberMe = useSelector(state => state.userDetails.rememberMe);
  const [userRolePermissionsArray, setUserRolePermissionsArray] =
    useState(null);
  const [routesArray, setRoutesArray] = useState(null);
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  useEffect(() => {
    // Use a Set to ensure unique values in the array
    const updatedArray = new Set();
    const routesArray = new Set();
    // Iterate through userRolePermissions
    userRolePermissions?.forEach(permission => {
      const allLevelsTrue =
        !permission.level1 &&
        !permission.level2 &&
        !permission.level3 &&
        !permission.level4 &&
        !permission.level5;

      if (!allLevelsTrue) {
        // Add the permissionName to the Set
        updatedArray.add(permission.permissionName);
        if (permission.permissionName.includes('Carriers')) {
          routesArray.add('/carriers');
        }
        if (permission.permissionName.includes('Users')) {
          routesArray.add('/users');
        }
        if (permission.permissionName.includes('Shipments')) {
          routesArray.add('/manage-shipments');
        }

        if (permission.permissionName.includes('Locations')) {
          routesArray.add('/view-locations');
        }
        if (permission.permissionName.includes('Settings')) {
          routesArray.add('/settings');
        }
        if (permission.permissionName.includes('Quick')) {
          routesArray.add('/quick-quote');
        }
      }
    });

    // Convert the Set back to an array and update the state
    setUserRolePermissionsArray(Array.from(updatedArray));
    setRoutesArray(Array.from(routesArray));
  }, [userRolePermissions]);
  const handleLogout = () => {
    AagencyLogout()
      .then(res => console.log(res, 'AagencyLogout'))
      .catch(err => console.log(err, 'AagencyLogoutErr'));
    localStorage.clear();
    !rememberMe && dispatch(setPasswordRedux(''));
    !rememberMe && dispatch(setEmailRedux(''));
    !rememberMe && dispatch(setUserDetailsObj({}));
    localStorage.clear();
    document.cookie =
      'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    dispatch(setUserToken(''));
    dispatch(setLoginAgencyID(''));
    dispatch(setUserRolePermissionsArr([]));
    dispatch(setUserToken(''));
    dispatch(setLoginAgencyID(''));
    dispatch(setRateValues(''));
    dispatch(setQuickQuoteRateValues(''));
    dispatch(setQuickQuotePickUpCheckedRedux(false));
    dispatch(setQuickQuoteDeliveryCheckedRedux(false));
    dispatch(setClassWeight(''));
    dispatch(setDimensionsWeight(''));
    dispatch(setUserRolePermissionsArr([]));
  };

  const currentScreenText = {
    '/': 'Shipments',
    '/dashboard': '',
    '/manage-shipments': 'Shipments',
    '/carriers': 'Carriers',
    '/view-shipment': 'View Shipment',
    '/users': 'Users',
    '/view-locations': 'Locations',
    '/settings': 'Settings',
    '/notifications': 'Notifications',
    '/app-registration': 'App Registration',
    '/quick-quote': 'Quick Quote',
    '/get-rates': 'Quick Quote',
    '/Logs': 'Logs',
    '/user-profile': 'User Profile',
    '/bookshipment': 'Book Shipment',
    '/bookshipmentParcel': 'Book Shipment',
    '/bookshipmentTL': 'Book Shipment',
    '/dispatchshipmentLTL': 'Dispatch Shipment',
    '/dispatchshipmentParcel': 'Dispatch Shipment',
    '/dispatchshipmentTL': 'Dispatch Shipment',
    '/track-shipment': 'Track Shipment',
    '/create-carrier': 'Create Carrier'
  };

  const currentScreen = useLocation();

  const firstLetter = UserDetails.firstName
    ? UserDetails.firstName.charAt(0).toUpperCase()
    : '';

  const lastLetter = UserDetails.lastName
    ? UserDetails.lastName.charAt(0).toUpperCase()
    : '';

  const imageStyle = {
    objectFit: 'cover',
    background: 'white',
    borderRadius: '8px',
    height: '45px',
    width: '45px',
    backgroundColor: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#133'
  };
  const isMobile = useIsMobile();

  const [open, setOpen] = useState(false);
  const [notifView, setNotifView] = useState({});
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div style={isMobile ? { marginTop: -15 } : {}} class='top-nav-bar'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <Typography variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            {currentScreenText[currentScreen.pathname]}
          </Typography>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            {/* <TextField
              size='small'
              sx={{
                width: '20vw',
                marginBottom: '10px',
                padding: '5px'
                // borderRadius: '15px',
                // border: '1px solid'
              }}
              type='text'
              placeholder='Search...'
              // value={searchText}
              // onChange={handleSearchChange}
            /> */}

            <NotificationDialog
              notifView={notifView}
              isOpen={open}
              handleClose={handleClose}
            />

            <Dropdown
              onClick={() => setNotificationDropDown(!notificationDropDown)}
              isOpen={notificationDropDown}
              toggle={dropDownNotification}
              className='list-inline-item'
            >
              <DropdownToggle
                to='#'
                // className='header-item'
                id='notifficationdropdown'
                type='button'
                tag='a'
                aria-expanded='false'
              >
                <Badge
                  badgeContent={UserNotificationsObj?.count}
                  color='primary'
                >
                  <NotificationsIcon
                    className='mt-1 color-blue'
                    sx={{ fontSize: 25, color: 'rgb(25, 118, 210)' }}
                  />
                </Badge>
              </DropdownToggle>

              <DropdownMenu
                className='dropdown-menu-end'
                aria-labelledby='notifficationdropdown'
                end
                style={{ width: '350px', textAlign: 'center' }}
              >
                <div className='d-flex justify-content-between'>
                  <Typography className='text-black'>
                    Notifications ({UserNotificationsObj?.count})
                  </Typography>
                  {/* <Typography className='text-blue' sx={{ fontSize: 12 }}>
                    Mark all as read
                  </Typography> */}
                </div>

                <div className='mt-2' />

                {UserNotifications?.length === 0 ? (
                  <div className='my-3 mt-4'>
                    <Typography>No new notifications</Typography>
                  </div>
                ) : (
                  UserNotifications?.map(notiff => (
                    <NotificationCard
                      id={notiff.id}
                      title={notiff.title}
                      description={sanitizeHTML(notiff.message)}
                      time={formatNotificationTime(notiff.receivedOn)}
                      status={!notiff.isRead}
                      setGlobalOpen={setOpen}
                      setGlobalNotification={setNotifView}
                    />
                  ))
                )}

                <div className='d-flex w-100 mt-3 justify-content-end'>
                  <Link
                    to='/notifications'
                    className='text-decoration-underline text-blue'
                  >
                    View All
                  </Link>
                </div>
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              onClick={() => setUserProfile(!userProfile)}
              isOpen={userProfile}
              toggle={dropDownuserprofile}
              className='list-inline-item'
            >
              <DropdownToggle
                to='#'
                // className='header-item'
                id='userdropdown'
                type='button'
                tag='a'
                aria-expanded='false'
                // style={{ height: 29 }}
              >
                {isMobile ? (
                  <MenuIcon sx={{ color: 'black' }} />
                ) : AgencyAdminDetails?.userAvatar ? (
                  <img
                    src={base_logo_url + AgencyAdminDetails?.userAvatar}
                    alt='adminBase64Image'
                    width='200'
                    style={imageStyle}
                  />
                ) : (
                  <img
                    src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                      firstLetter + lastLetter
                    )}</text></svg>`}
                    // src={imageUrl}
                    alt={UserDetails.firstName}
                    style={imageStyle}
                  />
                )}
              </DropdownToggle>

              <DropdownMenu
                className='dropdown-menu-end'
                aria-labelledby='userdropdown'
                end
                style={{ width: '100%', textAlign: 'center' }}
              >
                {isMobile ? (
                  UserIsAdmin ? (
                    <>
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/dashboard'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/carriers'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          Carriers
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/users'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          Users
                        </Link>
                      </li>{' '}
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/manage-shipments'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          Shipments
                        </Link>
                      </li>{' '}
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/view-locations'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          Locations
                        </Link>
                      </li>{' '}
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/app-registration'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          App Registration
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/quick-quote'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          Quick Quote
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='dropdown-item'
                          to='/user-profile'
                          onClick={() => {
                            setShipmentColor(false);
                            setQuickColor(false);
                          }}
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className='dropdown-item'
                          onClick={handleLogout}
                          to='/agency-signin'
                        >
                          Logout
                        </Link>
                      </li>
                    </>
                  ) : (
                    userRolePermissionsArray && (
                      <>
                        <li>
                          <Link
                            className='dropdown-item'
                            to='/dashboard'
                            onClick={() => {
                              setShipmentColor(false);
                              setQuickColor(false);
                            }}
                          >
                            Dashboard
                          </Link>
                        </li>

                        {userRolePermissionsArray.filter(items =>
                          items.includes('Carriers')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/carriers'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              Carriers
                            </Link>
                          </li>
                        )}

                        {userRolePermissionsArray.filter(items =>
                          items.includes('Users')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/users'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              Users
                            </Link>
                          </li>
                        )}

                        {userRolePermissionsArray.filter(items =>
                          items.includes('Shipment')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/manage-shipments'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              Shipments
                            </Link>
                          </li>
                        )}

                        {userRolePermissionsArray.filter(items =>
                          items.includes('Locations')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/view-locations'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              Locations
                            </Link>
                          </li>
                        )}

                        {userRolePermissionsArray.filter(items =>
                          items.includes('App-Registration')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/app-registration'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              App Registration
                            </Link>
                          </li>
                        )}

                        {userRolePermissionsArray.filter(items =>
                          items.includes('Settings')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/settings'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              Settings
                            </Link>
                          </li>
                        )}
                        {userRolePermissionsArray.filter(items =>
                          items.includes('App-Registration')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/app-registration'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              App Registration
                            </Link>
                          </li>
                        )}

                        {userRolePermissionsArray.filter(items =>
                          items.includes('Quick Quote')
                        ).length > 0 && (
                          <li>
                            <Link
                              className='dropdown-item'
                              to='/quick-quote'
                              onClick={() => {
                                setShipmentColor(false);
                                setQuickColor(false);
                              }}
                            >
                              Quick Quote
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link
                            className='dropdown-item'
                            to='/user-profile'
                            onClick={() => {
                              setShipmentColor(false);
                              setQuickColor(false);
                            }}
                          >
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className='dropdown-item'
                            onClick={handleLogout}
                            to='/agency-signin'
                          >
                            Logout
                          </Link>
                        </li>
                      </>
                    )
                  )
                ) : (
                  <>
                    <li>
                      <Link
                        className='dropdown-item'
                        to='/user-profile'
                        onClick={() => {
                          setShipmentColor(false);
                          setQuickColor(false);
                        }}
                      >
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        className='dropdown-item'
                        onClick={handleLogout}
                        to='/agency-signin'
                      >
                        Logout
                      </Link>
                    </li>
                  </>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(NavBar);
