







import { Box } from "@material-ui/core";
import { Autocomplete, Badge, Button, Checkbox, CircularProgress, createFilterOptions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MaterialReactTable from "material-react-table";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { GetNotificationSettings, GetUsers, SaveNotificationSettings } from "../../apis/Agency";


import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';


import './CreateUser.css';


function NotificationSettings() {

    const UserLoggedInEmail = useSelector(state => state.userDetails.email);

    const AgencyID = useSelector(state => state.userDetails.agencyLoginID);


    const isMobile = useIsMobile();

    const [open, setOpen] = useState(false);


    const [notifId, setNotifId] = useState(0);

    const activeOptions = ['Active', 'In-Active'];
    const [active, setActive] = useState(activeOptions[0]);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [timeValue, setTimeValue] = useState('');

    const timeUnitOptions = ['Mins', 'Hrs', 'Days'];
    const [timeUnit, setTimeUnit] = useState('Hrs');

    const [loading, setLoading] = useState(false);
    const [fetchLoader, setFetchLoader] = useState(false);

    const [emails, setEmails] = useState(UserLoggedInEmail);

    const statusOptions = ['Booked', 'In Transit', 'Delivered', 'Delayed']
    const [statusValue, setStatusValue] = useState([statusOptions[0],]);

    const typeOptions = ['Email', 'In-App']
    // const [typeValue, setTypeValue] = useState([typeOptions[0],]);
    const [typeValue, setTypeValue] = useState(typeOptions);

    const actionOptions = ['Success', 'Failure']
    const [actionValue, setActionValue] = useState(actionOptions[0]);


    const [agencyAdmin, setAgencyAdmin] = useState(false);
    const [loggedUsers, setLoggedUsers] = useState(false);


    const [validationErrors, setValidationErrors] = useState({});

    const [allNotifications, setAllNotifications] = useState([]);

    const [allUsers, setAllUsers] = useState([]);
    const [usersValues, setUsersValues] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);


    const fetchNotifications = () => {
        setFetchLoader(true)

        GetNotificationSettings().then(res => {
            setFetchLoader(false)
            setAllNotifications(res?.data?.data)
        }).catch(err => {
            setFetchLoader(false)
            console.log(err, 'NotificationsError')
        })

    }



    const fetchUsers = () => {
        GetUsers(AgencyID)
            .then(response => {
                if (response.status === 200) {
                    const curUser = response?.data?.data?.filter(
                        users => users.email === UserLoggedInEmail
                    ).map(uzer => ({ name: `${uzer.firstName} ${uzer.lastName}`, email: uzer.email }));
                    const appUsers = response?.data?.data?.map(uzer => ({ name: `${uzer.firstName} ${uzer.lastName}`, email: uzer.email }))
                    setAllUsers(appUsers);
                    setCurrentUser(curUser)
                    setUsersValues(curUser)
                }
            })
            .catch(err => {
                setAllUsers([]);
                console.log('eeeeeeeee', err.message);
            });
    };

    useEffect(() => {
        fetchNotifications();
        fetchUsers();
    }, [])


    const getUnitedTime = (ftim, funit) => {
        // return (funit === 'Hrs' ? ftim / 60 : funit === 'Days' ? ftim / 1440 : ftim)
        return (funit === 'Hrs' ? ftim / 60 : funit === 'Days' ? ftim : ftim)
    }


    const handleUpdateNotification = (rowData) => {
        setNotifId(rowData.id);
        setName(rowData.name);
        setActive(rowData.active ? activeOptions[0] : activeOptions[1]);
        setDescription(rowData.description);
        setStatusValue(rowData.shipmentStatuses.split(','));
        setTypeValue(rowData.types.split(','));

        setTimeValue(getUnitedTime(rowData.notifyAfter, rowData.notifyAfterUnits));

        setTimeUnit(rowData.notifyAfterUnits);
        setEmails(rowData.notifyTo);
        setValidationErrors({});

        const iaus = rowData.notifyInternal.split(',');
        const iappusers = allUsers.filter(userss => iaus.includes(userss.email));

        setUsersValues(iappusers)

        setOpen(true);


    };

    const notificationColumns = [
        {
            accessorKey: 'name',
            id: 'name',
            header: 'Notification Name',
            Cell: ({ cell, row }) => (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span class='mx-1'>{cell.getValue()}</span>
                    </Box>
                    <div
                        className={isMobile ? '' : 'table-row-buttons-users py-2'}
                        style={{ marginLeft: -60 }}
                    >
                        <button
                            onClick={event => handleUpdateNotification(row.original)}
                            class=' bg-white border-0 p-2 fw-bold'
                            style={{
                                color: '#3361FF',
                                borderRadius: '4px',
                                marginTop: 5
                            }}
                        >
                            <Tooltip title='View / Update'>
                                <svg
                                    className='MX-1'
                                    width='17'
                                    height='17'
                                    viewBox='0 0 13 13'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M1.33333 11.1693H2.26667L8.01667 5.41927L7.08333 4.48594L1.33333 10.2359V11.1693ZM10.8667 4.4526L8.03333 1.6526L8.96667 0.719271C9.22222 0.463715 9.53611 0.335938 9.90833 0.335938C10.2806 0.335938 10.5944 0.463715 10.85 0.719271L11.7833 1.6526C12.0389 1.90816 12.1722 2.21649 12.1833 2.5776C12.1944 2.93872 12.0722 3.24705 11.8167 3.5026L10.8667 4.4526ZM9.9 5.43594L2.83333 12.5026H0V9.66927L7.06667 2.6026L9.9 5.43594Z'
                                        fill='#3361FF'
                                    />
                                </svg>
                            </Tooltip>
                        </button>
                    </div>
                </>
            )
        },
        {
            accessorKey: 'notifyAfter',
            id: 'time',
            header: 'Notify After',
            Cell: ({ cell, row }) => (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span class='mx-1'>{getUnitedTime(cell.getValue(), row.original.notifyAfterUnits)} {row.original.notifyAfterUnits}</span>
                    </Box>
                </>
            )
        },

        {
            accessorKey: 'types',
            id: 'type',
            header: 'Notification Type',
            Cell: ({ cell, row }) => (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span class='mx-1'>{cell.getValue()}</span>
                    </Box>
                </>
            )
        },

        {
            accessorKey: 'active',
            id: 'status',
            header: 'Status',
            Cell: ({ cell, row }) => (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {cell.getValue() ? (
                            <span class='mx-1 text-success'>
                                ACTIVE{' '}
                                <svg
                                    width='18'
                                    height='18'
                                    viewBox='0 0 18 18'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M8.99719 4.3725L11.3747 6.75L12.4322 5.6925L8.99719 2.25L5.55469 5.6925L6.61969 6.75L8.99719 4.3725ZM8.99719 13.6275L6.61969 11.25L5.56219 12.3075L8.99719 15.75L12.4397 12.3075L11.3747 11.25L8.99719 13.6275Z'
                                        fill='#2E7D32'
                                    />
                                </svg>
                            </span>
                        ) : (
                            <span class='mx-1 text-muted'>
                                INACTIVE{' '}
                                <svg
                                    width='18'
                                    height='18'
                                    viewBox='0 0 18 18'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M8.99719 4.3725L11.3747 6.75L12.4322 5.6925L8.99719 2.25L5.55469 5.6925L6.61969 6.75L8.99719 4.3725ZM8.99719 13.6275L6.61969 11.25L5.56219 12.3075L8.99719 15.75L12.4397 12.3075L11.3747 11.25L8.99719 13.6275Z'
                                        fill='black'
                                        fill-opacity='0.6'
                                    />
                                </svg>
                            </span>
                        )}
                    </Box>
                </>
            )
        },
    ];


    const handleClose = () => {
        setOpen(false);
        setNotifId(0);
        setName('');
        setActive(activeOptions[0]);
        setDescription('');
        setStatusValue([statusOptions[0],]);
        setTypeValue(typeOptions);
        setTimeValue('');
        setTimeUnit('Hrs');
        setEmails(UserLoggedInEmail);
        setUsersValues(currentUser)
        setValidationErrors({});
    };




    const handleSubmitNotification = () => {
        setLoading(true)

        const types = typeValue.join(',')
        const statuses = statusValue.join(',')

        const iapues = usersValues.map(us => us.email)
        const notifyInternalVal = iapues.join(',')


        const intTime = parseInt(timeValue);

        const notifAfter = timeUnit === 'Mins' ? intTime : timeUnit === 'Hrs' ? intTime * 60 : intTime;

        const finalData = {
            id: notifId,
            name: name,
            description: description,
            types: types,
            shipmentStatuses: statuses,
            notifyAfter: notifAfter,
            notifyTo: emails,

            notifyAfterUnits: timeUnit,

            notifyInternal: notifyInternalVal,

            notifyFrom: "string",
            active: active === 'Active'
        }

        SaveNotificationSettings(finalData).then(res => {
            setLoading(false)
            successToast('Notification settings saved successfully.')
            fetchNotifications();
            handleClose();

        }).catch(err => {
            setLoading(false)
            ErrorToast(err?.message || 'Save notification settings failed')
        })
    }


    const handleSaveNotification = () => {
        setValidationErrors({})

        if (!name.trim()) {
            setValidationErrors({ ...validationErrors, name: 'Please give valid notification name' })
            return;
        }
        else if (!description.trim()) {
            setValidationErrors({ ...validationErrors, description: 'Please give valid notification description' })
            return;
        }
        else if (statusValue.length === 0) {
            setValidationErrors({ ...validationErrors, status: 'Please select atleast one shipment status' })
            return;
        }
        else if (typeValue.length === 0) {
            setValidationErrors({ ...validationErrors, ntype: 'Please select atleast one notification type' })
            return;
        }
        else if (!timeValue) {
            setValidationErrors({ ...validationErrors, time: 'Invalid time' })
            return;
        }
        else if (timeValue) {
            let intTime = parseInt(timeValue);
            if (timeUnit === 'Hrs' && (intTime < 1)) {
                setValidationErrors({ ...validationErrors, time: 'Invalid time' })
                return;
            }
            else if (timeUnit === 'Mins' && (intTime < 1)) {
                setValidationErrors({ ...validationErrors, time: 'Invalid time' })
                return;
            }
            else if (timeUnit === 'Days' && (intTime < 1)) {
                setValidationErrors({ ...validationErrors, time: 'Invalid time' })
                return;
            }
        }
        if (typeValue.includes('Email')) {
            if (!emails.trim()) {
                setValidationErrors({ ...validationErrors, emailError: 'Please eneter valid email addresses' })
                return;
            }
            else {
                const emailRegex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
                if (!emailRegex.test(emails)) {
                    setValidationErrors({
                        ...validationErrors,
                        emailError: 'Please give all valid email addresses'
                    });
                    return;
                }
            }
        }
        else {
        }

        handleSubmitNotification();


    }




    const handleEmailValidation = email => {
        const emailRegex =
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

        const errors = {};
        setEmails(email);

        if (emailRegex.test(email)) {
            // setIsValidEmail(true);
            setValidationErrors({
                ...validationErrors,
                emailError: null
            });
        } else {
            // setIsValidEmail(false);
            errors['emailError'] =
                'Please give all valid email addresses';
        }
        setValidationErrors(errors);
    };


    return (
        <Box>
            <ToastContainer
                position='top-center'
                style={
                    isMobile
                        ? { width: '100%', display: 'flex', justifyContent: 'center' }
                        : { width: 'auto' }
                }
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />


            <Dialog
                fullWidth={isMobile ? true : false}
                PaperProps={{
                    style: {
                        minWidth: '40vw',
                        minHeight: '80vh'
                    }
                }}
                className='InnerDialog'
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>Add Notification Setting</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>

                        <Box>


                            <TextField
                                fullWidth
                                sx={{ mt: 2 }}
                                label='Name'
                                size='small'
                                value={name}
                                onChange={e => {
                                    setName(e.target.value)
                                    setValidationErrors({})
                                }}
                                disabled={notifId !== 0}
                                required
                                error={Boolean(validationErrors.name)}
                                helperText={validationErrors.name}
                                FormHelperTextProps={{ sx: { mt: 0 } }}
                            />
                            <div className="d-flex align-items-end">
                                <Typography className="text-black mt-2">Description<sup>*</sup></Typography>
                                <Typography style={{ fontSize: 12, }} className="ms-2 text-danger">{validationErrors.description}</Typography>
                            </div>
                            <ReactQuill
                                style={{ height: '150px' }}
                                theme='snow' bounds={true}
                                value={description} onChange={setDescription}
                            // modules={CKModules}
                            />

                            <div style={{ marginTop: 50 }} />
                            <div className="d-flex align-items-center justify-content-between">
                                <Autocomplete
                                    options={statusOptions}
                                    value={statusValue}
                                    onChange={(event, newValue) => { setStatusValue(newValue); setValidationErrors({}) }}
                                    getOptionLabel={(option) => (option)}
                                    renderOption={(props, option) => <MenuItem {...props}>
                                        <div className="d-flex flex-column" style={{ display: 'flex' }}>
                                            <div style={{ fontSize: 13, color: 'black', }}>
                                                {option}
                                            </div>
                                            <div className="" style={{ fontSize: 11, whiteSpace: 'wrap' }}>
                                                {
                                                    option === 'Booked' ?
                                                        `If we don’t receive the picked-up status within a given time in notify after field.`
                                                        : option === 'In Transit' ?
                                                            `If the shipment is picked up and we don’t receive any next status within the given time in notify after field.`
                                                            : option === 'Delivered' ?
                                                                `If we don’t receive the Delivered status within a given time in notify after field.`
                                                                : ''
                                                }
                                            </div>
                                        </div>
                                    </MenuItem>}
                                    size="small"
                                    disableClearable={true}
                                    multiple={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            required
                                            margin="normal"
                                            label="Shipment Status"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            onKeyDown={(e) => e.preventDefault()}
                                            size="small"
                                            error={Boolean(validationErrors.status)}
                                            helperText={validationErrors.status}
                                            FormHelperTextProps={{ sx: { mt: 0 } }}
                                        />
                                    }
                                    sx={{ width: '75%' }}
                                />

                                <Autocomplete
                                    options={activeOptions}
                                    value={active}
                                    onChange={(event, newValue) => setActive(newValue)}
                                    getOptionLabel={(option) => (option)}
                                    renderOption={(props, option) => <MenuItem {...props} >{option}</MenuItem>}
                                    size="small"
                                    disableClearable={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            required
                                            margin="normal"
                                            label="Notification Status"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            onKeyDown={(e) => e.preventDefault()}
                                            size="small"
                                        // helperText={' '}
                                        />
                                    }
                                    sx={{ width: '24%' }}
                                />
                            </div>

                            <div className="d-flex align-items-center justify-content-between">

                                <Autocomplete
                                    options={typeOptions}
                                    value={typeValue}
                                    onChange={(event, newValue) => { setTypeValue(newValue); setValidationErrors({}) }}
                                    getOptionLabel={(option) => (option)}
                                    renderOption={(props, option) => <MenuItem {...props} >{option}</MenuItem>}
                                    size="small"
                                    disableClearable={true}
                                    multiple={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            required
                                            margin="normal"
                                            label="Notification Type"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            onKeyDown={(e) => e.preventDefault()}
                                            size="small"
                                            error={Boolean(validationErrors.ntype)}
                                            helperText={validationErrors.ntype || ' '}
                                            FormHelperTextProps={{ sx: { mt: 0 } }}
                                        />
                                    }
                                    sx={{ width: '50%' }}
                                />

                                <TextField
                                    sx={{ mt: 1, width: '25%' }}
                                    label={`Notify After`}
                                    size='small'
                                    value={timeValue}
                                    onChange={e => {
                                        setTimeValue(e.target.value)
                                        setValidationErrors({})
                                    }
                                    }
                                    type="number"
                                    required
                                    error={Boolean(validationErrors.time)}
                                    helperText={validationErrors.time || 'e.g. 24 Hrs'}
                                    FormHelperTextProps={{ sx: { mt: 0 } }}
                                />
                                <Autocomplete
                                    options={timeUnitOptions}
                                    value={timeUnit}
                                    onChange={(event, newValue) => setTimeUnit(newValue)}
                                    getOptionLabel={(option) => (option)}
                                    renderOption={(props, option) => <MenuItem {...props} >{option}</MenuItem>}
                                    size="small"
                                    disableClearable={true}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            required
                                            margin="normal"
                                            label="Unit"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            // onKeyDown={(e) => e.preventDefault()}
                                            size="small"
                                            helperText={' '}
                                        />
                                    }
                                    sx={{ width: '20%' }}
                                />
                            </div>


                            {/* <Typography className="text-blue mt-">Notify to</Typography> */}

                            {typeValue.includes('In-App') &&


                                <Autocomplete
                                    multiple
                                    sx={{}}
                                    className='w-100'
                                    size='small'
                                    disableClearable
                                    value={usersValues}
                                    onChange={(event, newValue) => {
                                        const sall = newValue.filter(
                                            loc => loc.selectAll === true
                                        );
                                        if (sall.length > 0) {
                                            if (usersValues.length === allUsers.length) {
                                                setUsersValues([]);
                                            } else {
                                                setUsersValues(allUsers);
                                            }
                                        } else {
                                            setUsersValues(newValue.filter(loc => !loc.selectAll));
                                        }
                                        setValidationErrors({});
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <MenuItem {...props} >
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                                checkedIcon={<CheckBoxIcon fontSize='small' />}
                                                style={{ marginRight: 8 }}
                                                checked={
                                                    option.selectAll
                                                        ? usersValues.length ===
                                                        allUsers.length
                                                        : selected
                                                }
                                                onChange={e => {
                                                    if (option.selectAll) {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                            {`${option.name} ${option.email}`}
                                        </MenuItem>
                                    )}
                                    getOptionLabel={(option) => (option.name)}
                                    // options={new Array(...allUsers)}
                                    options={[
                                        { name: 'Select All', email: '', selectAll: true },
                                        ...allUsers
                                    ]}
                                    filterOptions={(options, params) => {
                                        const { inputValue } = params;
                                        const filtered = options.filter(uzer => (uzer.name.toLowerCase().includes(inputValue.toLowerCase()) || uzer.email.includes(inputValue)));
                                        return filtered;
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            required
                                            margin="normal"
                                            label="In-App Users"
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            size="small"
                                            error={Boolean(validationErrors.ntype)}
                                            helperText={validationErrors.ntype || ' '}
                                            FormHelperTextProps={{ sx: { mt: 0 } }}
                                        />
                                    }
                                />
                            }


                            {typeValue.includes('Email') &&

                                <TextField
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    label='Email Addresses'
                                    size='small'
                                    value={emails}
                                    onChange={e => {
                                        handleEmailValidation(e.target.value)
                                        // setEmails(e.target.value)
                                        // setValidationErrors({})
                                    }}
                                    required
                                    error={Boolean(validationErrors.emailError)}
                                    helperText={validationErrors.emailError || 'To add multiple email addresses, please separate each address with a comma'}
                                    FormHelperTextProps={{ sx: { mt: 0 } }}
                                />
                            }



                        </Box>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {loading &&

                        <CircularProgress size={25} className="me-2" />
                    }
                    <Button size='small' variant='outlined' onClick={handleClose}>
                        Cancel
                    </Button>

                    {/* {
                        notifId !== 0 &&

                        <Button
                            size='small'
                            variant='contained'
                            color={status ? 'error' : 'info'}
                            onClick={() => handleInactiveNotification(!status)}
                            disabled={loading}
                            autoFocus
                        >
                            {status ? 'In-Active': 'Active'}
                        </Button>

                    } */}


                    <Button
                        size='small'
                        variant='contained'
                        color='success'
                        onClick={() => handleSaveNotification()}
                        disabled={loading}
                        autoFocus
                    >
                        {notifId === 0 ? 'Add' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>


            <MaterialReactTable

                columns={notificationColumns}
                data={allNotifications}

                muiTableContainerProps={{
                    sx: { maxHeight: '60vh', minHeight: '60vh', mb: 0 }
                }}


                state={{ isLoading: fetchLoader }}

                // enableRowActions
                enableRowSelection


                renderTopToolbarCustomActions={({ table }) => {
                    return (
                        <div
                            style={
                                isMobile
                                    ? {
                                        display: 'flex',
                                        flexDirection: 'column-reverse'
                                    }
                                    : { display: 'flex' }
                            }
                        >
                            <Button
                                size='small'
                                onClick={() => {
                                    setNotifId(0)
                                    setOpen(true)
                                }}
                                className='m-2'
                                style={
                                    isMobile
                                        ? {
                                            fontSize: 10,
                                            whiteSpace: 'nowrap',
                                            color: '#3361FF',
                                            backgroundColor: '#E4F3FD'
                                        }
                                        : {
                                            color: '#3361FF',
                                            backgroundColor: '#E4F3FD'
                                        }
                                }
                                variant='contained'
                            >
                                Add Settings
                                <svg
                                    className='ms-2'
                                    width='11'
                                    height='10'
                                    viewBox='0 0 11 10'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M4.94531 9.72396V5.72396H0.945312V4.39062H4.94531V0.390625H6.27865V4.39062H10.2786V5.72396H6.27865V9.72396H4.94531Z'
                                        fill='#3361FF'
                                    />
                                </svg>
                            </Button>
                        </div>
                    );
                }}

            />
        </Box>
    )
}

export default NotificationSettings;