export const sanitizeHTML = html => {
  const sanitizedHTML = html.replace(/<(\w+)[^>]*>.*?<\/\1>|<.*?\/>/g, tag => {
    return tag; // Preserve complete tags
  });
  return sanitizedHTML;
};

export const formatNotificationTime = timestamp => {
  let date = new Date(timestamp);

  let hours = date.getHours();
  let minutes = date.getMinutes();

  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Month is 0-indexed
  let day = date.getDate();

  // Format date to MM/DD/YYYY
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
};

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const formatDateUTC = date => {
  if (!date) return '';
  const localDate = new Date(date);
  const utcDate = new Date(
    Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
  );
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const month = monthNames[utcDate.getUTCMonth()];
  const year = utcDate.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const deformatDateUTC = dDate => {
  const [date, year] = dDate.split(',');
  const month = date.slice(0, 3);
  const day = date.slice(3);

  const montth = (monthNames.indexOf(month) + 1).toString();

  return `${year.trim()}-${montth.padStart(2, 0)}-${day.trim()}`;
};

export function formatPhoneNumber(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digit characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber; // Return the original number if it doesn't match
}
