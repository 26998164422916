import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box, Button, Typography } from '@mui/material';
import './TrackShipment.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import TrackHistory from './TrackHistory';
import { grey } from '@mui/material/colors';
const theme = createTheme({
  statusColors: {
    3: grey[300],
    default: 'success.main'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 1, // Remove transparency for disabled buttons
            color: '#fff'
          }
        }
      }
    }
  }
});

const StatusButton = ({ status }) => {
  const theme = useTheme();

  return (
    <Button
      disabled
      size='large'
      variant='contained'
      sx={{
        p: 1,
        backgroundColor:
          theme.statusColors[status] || theme.statusColors.default,
        color: '#fff',
        '&.Mui-disabled': {
          backgroundColor:
            theme.statusColors[status] || theme.statusColors.default
        }
      }}
    >
      Delivered <LocalShippingIcon sx={{ fontSize: 40 }} />
    </Button>
  );
};
const adjustTracingLinesOrder = tracingLines => {
  if (tracingLines) {
    // Check the descriptions of the first and last elements
    const isPickupLast = /Pickup/i.test(
      tracingLines[tracingLines?.length - 1]?.description
    );
    const isDeliveredFirst = /Delivered/i.test(tracingLines[0]?.description);

    // Reverse the array if necessary
    if (isPickupLast && isDeliveredFirst) {
      return tracingLines?.reverse();
    }
    return tracingLines; // Keep as-is if already in correct order
  }
};
const generateSteps = (trackShipmentData, rowData) => {
  const steps = [];
  const adjustedTracingLines = adjustTracingLinesOrder(
    trackShipmentData?.tracingLines
  );

  const firstStep = adjustedTracingLines?.find(line =>
    /Picked Up|ACTUAL_PICKUP|PICK UP MADE|PickUp/i.test(line.description)
  );

  // if (firstStep) {
  steps.push({
    label: 'Picked Up',
    date: firstStep
      ? firstStep.startDateTime
      : trackShipmentData.pickupDate || '',
    pickupLoc:
      rowData.originCity +
      ' ' +
      rowData.originState +
      ' ' +
      rowData.originZipCode,
    completed: true // Always completed because it's the first step
  });
  // }

  // Step 2: In Transit (no date needed)
  steps.push({
    label: 'In Transit',
    date: '', // No date
    completed: true // Mark as incomplete
  });

  // Step 3: At Destination Terminal (no date needed)
  steps.push({
    label: 'At Destination Terminal',
    date: '', // No date
    completed: true // Mark as incomplete
  });

  // Step 4: Last step with "Delivered" or "Actual Delivery" match
  const lastStep = adjustedTracingLines?.find(line =>
    /Delivered|ACTUAL_DELIVERY/i.test(line.description)
  );

  // if (lastStep) {
  steps.push({
    label: 'Delivered',
    date:
      trackShipmentData?.shipmentStatus === 3
        ? ''
        : lastStep
        ? lastStep.endDateTime
          ? lastStep.startDateTime
          : lastStep.startDateTime
        : trackShipmentData.deliveryDate || '',
    deliveryLoc:
      trackShipmentData?.shipmentStatus === 3
        ? ''
        : rowData.destinationCity +
          ' ' +
          rowData.destinationState +
          ' ' +
          rowData.destinationZipCode,
    completed: true // Mark as completed
  });
  // }

  return steps;
};

export default function TrackShipmentDetails(props) {
  const trackShipmentData = props?.trackShipmentData;
  const status = trackShipmentData?.shipmentStatus;
  const rowData = props?.rowData;
  const steps = generateSteps(trackShipmentData, rowData);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2
      }}
    >
      <Box>
        <Typography variant='h4'>PRO NUMBER | {rowData?.proNumber}</Typography>
      </Box>
      {trackShipmentData?.tracingLines?.length > 0 && (
        <TrackHistory
          trackShipmentData={trackShipmentData}
          // view={viewType}
        />
      )}

      <Timeline
        sx={{
          mt: 3,
          ml: 5,
          whiteSpace: 'nowrap',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'c'
        }}
      >
        <TimelineDot
          sx={{
            width: 22,
            height: 22,
            mr: 1,
            mt: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          color={
            status === 0
              ? 'primary'
              : status === 1
              ? 'secondary'
              : status === 2
              ? 'info'
              : status === 3
              ? 'warning'
              : status === 3
              ? 'grey'
              : 'success'
          }
        >
          <CheckCircleIcon sx={{ fontSize: '18' }} />
        </TimelineDot>
        {steps.map((step, index) => (
          <Box key={index}>
            <TimelineSeparator>
              <div style={{}}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'self-start',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={
                      index === 2
                        ? {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: -90
                          }
                        : (index === 3 && status === 4) ||
                          (index === 1 && status === 3)
                        ? {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: -60
                          }
                        : {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: -50
                          }
                    }
                    className='View_Details_Headings'
                  >
                    <Typography variant={'body2'} fontWeight={'bold'}>
                      {step.label}
                    </Typography>
                    <div style={{ position: 'absolute' }}>
                      <Typography sx={{ fontSize: 13, mt: 8 }}>
                        {step.date || ''}
                        <br />
                        <b>
                          {' '}
                          {step.pickupLoc}
                          {step.deliveryLoc}
                        </b>
                      </Typography>
                    </div>
                  </div>

                  {index !== steps.length - 1 && (
                    <div
                      style={
                        (index === 2 && status === 4) ||
                        (index === 0 && status === 3)
                          ? {
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: -10
                            }
                          : { display: 'flex', alignItems: 'center' }
                      }
                    >
                      <Box
                        sx={
                          status === 3 && index === 0
                            ? {
                                width: 200,
                                height: 3,
                                borderRadius: 2,
                                backgroundColor: 'warning.main',
                                mr: 1
                              }
                            : status === 3
                            ? {
                                width: 200,
                                height: 3,
                                borderRadius: 2,
                                backgroundColor: grey[300],
                                mr: 1
                              }
                            : index === 1 || index === 2
                            ? {
                                width: 200,
                                height: 3,
                                borderRadius: 2,
                                backgroundColor:
                                  status === 3
                                    ? 'warning.main'
                                    : 'success.main',
                                mr: 1
                              }
                            : {
                                width: 200,
                                height: 3,
                                borderRadius: 2,
                                backgroundColor:
                                  status === 3
                                    ? 'warning.main'
                                    : 'success.main',
                                mr: 1
                              }
                        }
                      />

                      <TimelineDot
                        sx={
                          (index === 2 && status === 4) ||
                          (index === 0 && status === 3)
                            ? {
                                width: 40,
                                height: 40,
                                mr: 1,
                                // mb: -1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }
                            : {
                                width: 22,
                                height: 22,
                                mr: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }
                        }
                        color={
                          status === 0
                            ? 'primary'
                            : status === 1
                            ? 'secondary'
                            : status === 2
                            ? 'info'
                            : status === 3 && index === 0
                            ? 'warning'
                            : status === 3
                            ? 'grey'
                            : 'success'
                        }
                      >
                        <CheckCircleIcon
                          sx={
                            (index === 2 && status === 4) ||
                            (index === 0 && status === 3)
                              ? { fontSize: '36' }
                              : { fontSize: '18' }
                          }
                        />
                      </TimelineDot>
                    </div>
                  )}
                </div>
              </div>
            </TimelineSeparator>
          </Box>
        ))}
      </Timeline>
    </Box>
  );
}
